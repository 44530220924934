import { useContext, useMemo } from 'react';
import { UserContext } from '../../contexts/UserContext';
import {
	Divider,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	ListItemSecondaryAction,
	Grid,
	FormHelperText,
	Typography,
	IconButton,
	Box,
	Tooltip,
} from '@mui/material';
import {
	AddCircleOutlineOutlined,
	DeleteOutlined,
	DragHandle,
	HelpOutlineOutlined,
} from '@mui/icons-material';
import { customFieldTypes } from '../../models/customFieldsTypes';
import { Formik } from 'formik';
import { string, number, object } from 'yup';

export function CadenceEditor(props) {
	const { cadence, onChange } = props;
	const userContext = useContext(UserContext);

	const phoneFields = useMemo(() => {
		const rawFields = new Set([
			...userContext.customFields
				.filter((x) => x.isPoc && x.type === customFieldTypes.Phone)
				.map((x) => x.name),
			'phone',
			'cell',
		]);

		for (const step of cadence) {
			rawFields.delete(step.phoneType);
		}

		return Array.from(rawFields).sort();
	}, [userContext.customFields, cadence]);

	const deleteStep = (data) => () => {
		const copy = JSON.parse(JSON.stringify(cadence));
		copy.splice(data - 1, 1);
		for (const [index, step] of copy.entries()) {
			step.order = index + 1;
		}

		onChange([...copy]);
	};

	return (
		<Grid container spacing={2} direction="column">
			<Grid item>
				<Formik
					initialValues={{ phoneType: '', delay: 1, order: cadence.length + 1 }}
					enableReinitialize
					validationSchema={object({
						phoneType: string()
							.required('A phone type is required')
							.oneOf(phoneFields),
						delay: number()
							.required('A delay is required')
							.integer('The delay must be an integer')
							.min(1, 'The delay must be at least 1'),
					})}
					onSubmit={async (values, formikBag) => {
						onChange([...cadence, JSON.parse(JSON.stringify(values))]);
						formikBag.resetForm();
					}}
				>
					{(formikProps) => (
						<Box
							display="grid"
							gridTemplateColumns="1fr 1fr"
							gap={2}
							alignItems="center"
						>
							<FormControl
								error={
									formikProps.errors?.phoneType &&
									formikProps.touched?.phoneType
								}
								required
							>
								<InputLabel>Phone Type</InputLabel>
								<Select
									label="Phone Type"
									name="phoneType"
									value={formikProps.values.phoneType}
									onChange={formikProps.handleChange}
								>
									{phoneFields.map((data, index) => (
										<MenuItem key={index} value={data}>
											{data}
										</MenuItem>
									))}
								</Select>
								{formikProps.touched?.phoneType &&
									formikProps.errors?.phoneType && (
										<FormHelperText error>
											{formikProps.errors.phoneType}
										</FormHelperText>
									)}
							</FormControl>
							<Box display="flex" gap={2} alignItems="center">
								<TextField
									required
									margin="dense"
									name="delay"
									label={
										<Box display="flex" alignItems="center" gap={1}>
											Delay
											<Tooltip title="After the final cadence step, the channel retry time will be used.">
												<HelpOutlineOutlined fontSize="small" color="primary" />
											</Tooltip>
										</Box>
									}
									type="number"
									error={
										formikProps.errors?.delay && formikProps.touched?.delay
									}
									helperText={
										formikProps.touched?.delay && formikProps.errors?.delay
									}
									InputProps={{
										inputProps: {
											min: 1,
										},
									}}
									value={formikProps.values.delay}
									onChange={formikProps.handleChange}
								/>
								<IconButton
									aria-label="add step"
									onClick={formikProps.handleSubmit}
								>
									<AddCircleOutlineOutlined color="primary" fontSize="small" />
								</IconButton>
							</Box>

						</Box>
					)}
				</Formik>
			</Grid>
			<Grid item style={{ width: '100%' }}>
				{cadence.length > 0 && (
					<List>
						<ListSubheader>
							<Grid container justifyContent="flex-start" alignItems="center">
								<Grid item xs={2}>
									Order
								</Grid>
								<Grid item xs={4}>
									Phone Type
								</Grid>
								<Grid item xs={4}>
									Delay
								</Grid>
							</Grid>
						</ListSubheader>
						<Divider />
						{cadence.map((step) => (
							<ListItem key={step.order}>
								<ListItemText
									primary={
										<Grid
											container
											justifyContent="flex-start"
											alignItems="center"
										>
											<Grid item xs={2}>
												{step.order}
											</Grid>
											<Grid item xs={4}>
												{step.phoneType}
											</Grid>
											<Grid item xs={4}>
												{step.order !== cadence.length ? step.delay : `-`}
											</Grid>
										</Grid>
									}
								/>
								<ListItemSecondaryAction>
									<Grid container alignItems="center">
										<Grid item>
											<IconButton
												aria-label="delete"
												onClick={deleteStep(step.order)}
											>
												<DeleteOutlined color="primary" fontSize="small" />
											</IconButton>
										</Grid>
										<Grid item>
											<DragHandle className="drag-handle" color="primary" />
										</Grid>
									</Grid>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				)}
				{cadence.length === 0 && (
					<Typography variant="body1" style={{ textAlign: 'center' }}>
						No cadence defined
					</Typography>
				)}
			</Grid>
		</Grid>
	);
}
