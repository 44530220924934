import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import CampaignStatusBreakdown from "./campaignStatusBreakdown";
import {
	EqualizerOutlined,
	PhoneOutlined,
	TextsmsOutlined,
	MailOutlined,
	NorthEastOutlined,
	SouthEastOutlined,
	RefreshOutlined,
} from "@mui/icons-material";
import {
	Table,
	TableBody,
	Paper,
	TableCell,
	Grid,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Box,
	ToggleButtonGroup,
	ToggleButton,
	Card,
	CardContent,
	Typography,
	CardHeader,
	LinearProgress,
	Button,
	IconButton
} from '@mui/material';
import { generateClient, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { esQuery } from "src/graphql/queries";
import moment from "moment";
import { Divider } from "@aws-amplify/ui-react";
import ActiveCalls from "./activeCalls";
import CallBacks from "./callBacks";
import { Gauge } from "@mui/x-charts";

const CampaignStatus = function (props) {
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [campaignStatus, setCampaignStatus] = useState();
	const [channel, setChannel] = useState("voice");

	const [totalContacts, setTotalContacts] = useState();
	const [completedContacts, setCompletedContacts] = useState();
	const [percentageComplete, setPercentageComplete] = useState();
	const [callsInProgress, setCallsInProgress] = useState(0);
	const [needsApproval, setNeedsApproval] = useState(0);
	const [dispositionCount, setDispositionCount] = useState(0);
	const [callBackCount, setCallBackCount] = useState(0);
	const [smsSent, setSMSSent] = useState();
	const [showCalls, setViewCalls] = useState(false);

	const [todayCalls, setTodayCalls] = useState();
	const [callDiff, setCallDiff] = useState();
	const [yesterdayCalls, setYesterdayCalls] = useState();

	const [showBreakdown, setShowBreakdown] = useState(false);
	const [breakdownId, setBreakdownId] = useState();

	const [actionEvents, setActionEvents] = useState([]);
	const [ratioEvents, setRatioEvents] = useState([]);

	const [showActiveCalls, setShowActiveCalls] = useState(false);
	const [showCallBacks, setShowCallBacks] = useState(false);

	const [refreshTime, setRefreshTime] = useState();

	useEffect(() => {
		//If there is configuration for the export SFTP then allow it as an action.
		if (props.campaign) {
			console.log(props.campaign);
			getCampaignStatus();
			getCampaignCallHistory(props.campaign.id);
			const interval = setInterval(() => {
				getCampaignStatus();
			}, 30000);
			return () => clearInterval(interval);
		}
	}, [props.campaign]);

	function getCallHistoryQuery(campaignId, startTime, endTime) {
		return {
			"query": {
				"bool": {
					"must": [
						{
							"term": {
								"tenant.keyword": userContext.tenantId
							}
						},
						{
							"match_phrase": {
								"type": "Dial"
							}
						},
						{
							"match_phrase": {
								"activityLogCampaignId": campaignId
							}
						},
						{
							"range": {
								"createdAt": {
									"gte": startTime,
									"lte": endTime
								}
							}
						}
					]
				}
			},
			"size": 0
		}
	}

	async function getCampaignCallHistory(campaignId) {
		try {
			let promises = [];
			promises.push(client.graphql({
				query: esQuery,
				variables: {
					model: 'activitylog',
					action: '_search',
					query: JSON.stringify(getCallHistoryQuery(campaignId, moment().startOf('day').toISOString(), moment().toISOString())),
				},
			}));
			promises.push(client.graphql({
				query: esQuery,
				variables: {
					model: 'activitylog',
					action: '_search',
					query: JSON.stringify(getCallHistoryQuery(campaignId, moment().startOf('day').subtract(1, 'days').toISOString(), moment().subtract(1, 'days').toISOString())),
				},
			}));
			const data = await Promise.all(promises);

			const today = JSON.parse(data[0].data.esQuery);
			const yesterday = JSON.parse(data[1].data.esQuery);

			const diff = today.hits.total - yesterday.hits.total;

			setTodayCalls(today.hits.total);
			setYesterdayCalls(yesterday.hits.total);
			setCallDiff(diff);

		} catch (error) {
			console.error(error);
		}
	}

	async function getCampaignStatus() {
		try {
			setRefreshTime(new Date().toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', second: '2-digit' }));
			const data = await post({
				apiName: "cdyxoutreach",
				path: "/cache/status",
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						"x-api-key": userContext.apiKey,
					},
					body: {
						campaignId: props.campaign.id,
						channel: channel,
					},
				},
			}).response;

			const getData = await data.body.json();

			//Setting Retrieved Data
			setTotalContacts(getData.totalContacts);



			//CompletedContacts
			let completed = 0;
			for (const segment of getData.campaignStats) {
				completed = completed + segment.completed;
			}
			setCompletedContacts(completed);

			// CompletedPercentage
			let percentage = '-';
			if (getData.totalContacts > 0) {
				percentage = ((completed / getData.totalContacts) * 100).toFixed(0);
			}
			setPercentageComplete(percentage);
			if (userContext.telephonyProvider === 'NiC') {
				setCallsInProgress(getData.active ? getData.active : 0);
			} else {
				setCallsInProgress(getData.inProgress ? getData.inProgress : 0);
			}

			if (props?.campaign?.profile?.clickToCall) {
				setNeedsApproval(getData.needsApproval ? getData.needsApproval : 0);
			}


			setCallBackCount(getData.callBacks ? getData.callBacks : 0);
			setDispositionCount(getData.dispositioning ? getData.dispositioning : 0);
			setSMSSent(getData.smsSent ? getData.smsSent : 0);
			setCampaignStatus([...getData.campaignStats]);
		} catch (err) {
			console.error(err);
		}
	}

	const toggleChannel = (event, value) => {
		if (value) {
			setChannel(value);
		}
	};

	return (
		<>
			{campaignStatus && <Box display="flex" flexDirection="column" gap={2}>
				<Box display="grid" gridTemplateColumns={(props?.campaign?.profile?.clickToCall) ? "3fr 1fr 1fr 1fr" : "3fr 1fr 1fr"} gap={2}>
					<Card elevation={0}
						sx={{
							backgroundColor: 'primary.light',
							borderRadius: '10px',
						}}
					>
						<CardContent>
							<Box display="flex" flexDirection="column" sx={{padding: 2}}>
								<Box display="flex" justifyContent="space-between">
									<Typography variant="h5">{props.campaign.name}</Typography>
									<Box display="flex" alignItems="center" gap={1}>
										<Typography variant="subtitle2">{refreshTime}</Typography>
										<Tooltip title="Refresh"><IconButton onClick={() => getCampaignStatus()}><RefreshOutlined color="primary" /></IconButton></Tooltip>
									</Box>
								</Box>
								<Box display="flex" justifyContent="space-between" alignItems="flex-end">
									<Box display="flex" flexDirection="column" justifyContent="space-between" >

										<Box display="flex" gap={2}>
											<Box display="flex" flexDirection="column" alignItems="center" >
												<Typography variant="h4">{todayCalls}</Typography>
												<Typography variant="subtitle2">Calls Today</Typography>
											</Box>
											<Divider orientation="vertical" flexItem />
											<Box display="flex" flexDirection="column" alignItems="center">
												<Box display="flex" alignItems="center" gap={1}>
													{/* {!isNaN(callDiff) && isFinite(callDiff) && <Typography variant="h4">{`${callDiff.toFixed(0)}%`}</Typography>} */}
													<Typography variant="h4">{callDiff}</Typography>
													{callDiff > 0 && <NorthEastOutlined color="primary" />}
													{callDiff < 0 && <SouthEastOutlined color="primary" />}
												</Box>
												<Typography variant="subtitle2">At This Time Yesterday</Typography>
											</Box>
										</Box>
									</Box>
									<Box display="flex" gap={2}>
										{/* <Box display="flex" flexDirection="column" alignItems="center" >
										<Typography variant="h4">{totalContacts}</Typography>
										<Typography variant="subtitle2">{`Across ${campaignStatus?.length} Segment(s)`}</Typography>
									</Box>
									<Divider orientation="vertical" flexItem />
									<Box display="flex" flexDirection="column" alignItems="center">
										<Box display="flex" flexDirection="column" alignItems="center" >
											<Typography variant="h4">{completedContacts}</Typography>
											<Typography variant="subtitle2">{percentageComplete}</Typography>
										</Box>
									</Box> */}
										<Box display="flex" flexDirection="column" alignItems="center" >
											<Gauge
												width={150}
												height={150}
												value={percentageComplete}
												startAngle={0}
												endAngle={360}
												innerRadius="70%"
												outerRadius="100%"
												text={`${completedContacts} / ${totalContacts}`}
											/>
											<Typography variant="subtitle2">Contacts Completed</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						</CardContent>
					</Card>
					<Card elevation={0}
						variant="outlined"
						sx={{
							borderRadius: '10px',
						}}
					>
						<CardHeader sx={{ textAlign: 'center' }} title="Calls in Progress" />
						<CardContent>
							<Box display="flex" flexDirection="column" alignItems="center" >
								<Typography variant="h2">{callsInProgress}</Typography>
								{userContext.telephonyProvider === 'NiC' && <Button disabled={callsInProgress < 1} onClick={() => setShowActiveCalls(true)}>View Calls</Button>}
							</Box>
						</CardContent>
					</Card>

					{userContext.telephonyProvider === 'NiC' && <Card elevation={0}
						variant="outlined"
						sx={{
							borderRadius: '10px',
						}}
					>
						<CardHeader sx={{ textAlign: 'center' }} title="Calls Dispositioning" />
						<CardContent>
							<Box display="flex" flexDirection="column" alignItems="center" >
								<Typography variant="h2">{dispositionCount}</Typography>
							</Box>
						</CardContent>
					</Card>}

					{props?.campaign?.profile?.clickToCall && <Card elevation={0}
						variant="outlined"
						sx={{
							borderRadius: '10px',
						}}
					>
						<CardHeader sx={{ textAlign: 'center' }} title="Need Approval" />
						<CardContent>
							<Box display="flex" flexDirection="column" alignItems="center" >
								<Typography variant="h2">{needsApproval}</Typography>
							</Box>
						</CardContent>
					</Card>}

					{userContext.telephonyProvider === 'Webex' && <Card elevation={0}
						variant="outlined"
						sx={{
							borderRadius: '10px',
						}}
					>
						<CardHeader title="Call Backs" sx={{ textAlign: 'center' }} />
						<CardContent>
							<Box display="flex" flexDirection="column" alignItems="center" >
								<Typography variant="h2">{callBackCount}</Typography>
								<Button disabled={callBackCount < 1} onClick={() => setShowCallBacks(true)}>View Call Backs</Button>
							</Box>
						</CardContent>
					</Card>}
				</Box>
				<Box display="grid" gridTemplateColumns="2fr 1fr" gap={2}>
					{!showBreakdown && <Box >
						<Card color="primary" elevation={0} variant="outlined" sx={{ borderRadius: '10px' }}>
							<CardHeader title={
								<Box display="flex" justifyContent="space-between">
									<Typography variant="h6">Segment Inventory</Typography>
									<ToggleButtonGroup
										size="small"
										value={channel}
										exclusive
										onChange={toggleChannel}
									>
										<ToggleButton value="voice">
											<Tooltip title="View Voice Inventory">
												<PhoneOutlined color="primary" fontSize="small" />
											</Tooltip>
										</ToggleButton>
										<ToggleButton value="sms">
											<Tooltip title="View SMS Inventory">
												<TextsmsOutlined color="primary" fontSize="small" />
											</Tooltip>
										</ToggleButton>
										<ToggleButton value="email">
											<Tooltip title="View Email Inventory">
												<MailOutlined color="primary" fontSize="small" />
											</Tooltip>
										</ToggleButton>
									</ToggleButtonGroup>
								</Box>
							} />
							<CardContent>
								{campaignStatus && channel === "voice" && (
									<Box>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>Segment</TableCell>
													<TableCell align="center">
														Fresh<br></br>Available / Total
													</TableCell>
													<TableCell align="center">
														Retry<br></br>Ready / Total
													</TableCell>
													<TableCell>Details</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{campaignStatus.map((row) => (
													<TableRow key={row.segment}>
														<TableCell component="th" scope="row">
															{row.segment}
														</TableCell>
														<TableCell align="center">{`${row.availableFresh} / ${row.fresh}`}</TableCell>
														<TableCell align="center">{`${row.readyRetry} / ${row.retry}`}</TableCell>
														<TableCell
															onClick={() => {
																setShowBreakdown(true)
																setBreakdownId(row.segmentId)
															}}
															align="center"
														>
															<EqualizerOutlined color="primary" />
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Box>
								)}

								{campaignStatus && channel === "sms" && (
									<Box>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>Segment</TableCell>
													<TableCell>Fresh</TableCell>
													<TableCell>Retry</TableCell>
													<TableCell>View Breakdown</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{campaignStatus.map((row) => (
													<TableRow key={row.segment}>
														<TableCell component="th" scope="row">
															{row.segment}
														</TableCell>
														<TableCell align="center">
															{row.smsFresh}
														</TableCell>
														<TableCell align="center">
															{row.smsRetry}
														</TableCell>
														<TableCell
															onClick={() => {
																setShowBreakdown(true)
																setBreakdownId(row.segmentId)
															}}
															align="center"
														>
															<EqualizerOutlined color="primary" />
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Box>
								)}

								{campaignStatus && channel === "email" && (
									<Grid item xs={12}>
										<TableContainer component={Paper}>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Segment</TableCell>
														<TableCell>Fresh</TableCell>
														<TableCell>Sent</TableCell>
														<TableCell>Retry</TableCell>
														<TableCell>View Breakdown</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{campaignStatus.map((row) => (
														<TableRow key={row.segment}>
															<TableCell component="th" scope="row">
																{row.segment}
															</TableCell>
															<TableCell align="center">
																{row.emailFresh}
															</TableCell>
															<TableCell align="center">
																{row.emailInDialer}
															</TableCell>
															<TableCell align="center">
																{row.emailRetry}
															</TableCell>
															<TableCell
																onClick={() => {
																	setShowBreakdown(true)
																	setBreakdownId(row.segmentId)
																}}
																align="center"
															>
																<EqualizerOutlined color="primary" />
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								)}

							</CardContent>
						</Card>
					</Box>}
					{showBreakdown && <Box>
						<CampaignStatusBreakdown
							campaignId={props.campaign.id}
							segmentId={breakdownId}
							channel={channel}
							setBreakdownId={setBreakdownId}
							setShowBreakdown={setShowBreakdown}
						/>
					</Box>}
					<Card variant="outlined"
						sx={{
							borderRadius: '10px',
						}}>
						<CardHeader title='Scheduled Action Events' titleTypographyProps={{
							variant: "body2",
							color: "textSecondary",
						}}
						/>
						<CardContent>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Time</TableCell>
										<TableCell>Timezone</TableCell>
										<TableCell>Days</TableCell>
										<TableCell>Action</TableCell>
										<TableCell>Delete</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{actionEvents.map(event => <TableRow key={event.id}>
										<TableCell>{moment(event.time, 'HH:mm').format('hh:mm a')}</TableCell>
										<TableCell>{event.timeZone}</TableCell>
										<TableCell>
											{event.days.includes(0) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SU.</ToggleButton>}
											{event.days.includes(1) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>MO.</ToggleButton>}
											{event.days.includes(2) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TU.</ToggleButton>}
											{event.days.includes(3) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>WE.</ToggleButton>}
											{event.days.includes(4) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TH.</ToggleButton>}
											{event.days.includes(5) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>FR.</ToggleButton>}
											{event.days.includes(6) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SA.</ToggleButton>}
										</TableCell>
										<TableCell>
											{event.action === 'start' && 'Start'}
											{event.action === 'pause' && 'Pause'}
											{event.action === 'restart' && 'Resume'}
											{event.action === 'reset' && 'Reset'}
											{event.action === 'stop' && 'End'}
										</TableCell>
										<TableCell><IconButton onClick={() => {
											setToDelete(event);
											setDeleteDialogOpen(true);
										}} ><DeleteOutline color="primary" /></IconButton></TableCell>
									</TableRow>)}
								</TableBody>
							</Table>
						</CardContent>
					</Card>
				</Box>

			</Box>}
			{!campaignStatus && <Box display="flex" justifyContent="center" alignItems="center">
				<LinearProgress color="primary" />
			</Box>}
			<ActiveCalls
				open={showActiveCalls}
				onClose={() => setShowActiveCalls(false)}
				campaignId={props.campaign?.id}
				campaignName={props.campaign?.name}
			/>
			<CallBacks
				open={showCallBacks}
				onClose={() => setShowCallBacks(false)}
				campaignId={props.campaign?.id}
				campaignName={props.campaign?.name}
			/>
		</>
	);
};
export default CampaignStatus;
