import { useContext, useMemo } from 'react';
import { UserContext } from '../../contexts/UserContext';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
} from '@mui/material';
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineOppositeContent
} from '@mui/lab'
import { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { getPaymentProfile } from 'src/graphql/queries';
import { actionOneButtonStyle } from 'src/theme';
/**
 * The ChartDialog component is used Create New Charts
 * Expects No params our query string values on the route
 * * @category Pages
 *  @component
 *
 */
export function CustomerJourneyDialog(props) {
	const userContext = useContext(UserContext);
	const client = generateClient();
	const [journey, setJourney] = useState([]);
	useEffect(() => {
		if (props.open && props.channels.length > 0) {
			setUpJourney();
		}

	}, [props.open, props.channels]);

	async function setUpJourney() {
		const journeyData = [];
		for (let i = 0; i < props.channels.length; i++) {
			const channel = props.channels.find(x => x.order === i + 1);
			switch (channel.type) {
				case 'Voice':
					const cadence = JSON.parse(channel.cadence);
					for (let attemptCount = 0; attemptCount < channel.maxAttempts; attemptCount++) {
						for (let j = 0; j < cadence.length; j++) {
							const step = cadence.find(x => x.order === j + 1);
							const attempt = {
								action: 'Call',
								delay: step.order === cadence.length ? channel.retryDelay : step.delay,
							};

							switch (step.phoneType) {
								case 'phone':
									attempt.device = 'Phone';
									break;
								case 'cell':
									attempt.device = 'Cell';
									break;
								default:
									const customField = userContext.customFields.find(x => x.name === step.phoneType);
									attempt.device = customField.displayName ? customField.displayName : step.phoneType;
									break;
							}

							journeyData.push(attempt);
						}
					}
					break;
				case 'SMS':
					const smsProfile = await client.graphql({
						query: getPaymentProfile,
						variables: { id: channel.paymentProfileId }
					});
					for (let i = 0; i < channel.maxAttempts; i++) {
						const attempt = {
							action: 'Send',
							device: 'SMS',
							delay: smsProfile.data.getPaymentProfile.ttl + channel.retryDelay,
						}
						journeyData.push(attempt);
					}
					break;
				case 'Email':
					const emailProfile = await client.graphql({
						query: getPaymentProfile,
						variables: { id: channel.paymentProfileId }
					});
					for (let i = 0; i < channel.maxAttempts; i++) {
						const attempt = {
							action: 'Send',
							device: 'Email',
							delay: emailProfile.data.getPaymentProfile.ttl + channel.retryDelay,
						}
						journeyData.push(attempt);
					}
					break;
				case 'Email':
					break;
				default:
					break;
			}
		}
		setJourney(journeyData);
	}


	return (
		<>
			<Dialog open={props.open} onClose={() => props.setOpen(false)} maxWidth="lg">
				<DialogTitle>Customer Journey</DialogTitle>
				<DialogContent sx={{ width: '30vw' }}>
					<Box display="flex" flexDirection="column">
						<Typography variant="overline">*Any Final Dispositions will end this journey</Typography>
						<Typography variant="overline">**Delay after SMS or Email is a combination of the TTL of the message determined in the digital profile and the retry time set on the channel</Typography>
						<Timeline>
							{journey.map((step, index) => (
								<TimelineItem>
									<TimelineOppositeContent>
										{index !== 0 ? `${journey[index - 1].delay} minute(s) till available` : 'Start'}
									</TimelineOppositeContent>
									<TimelineSeparator>
										<TimelineDot variant='outlined' color="primary" />
										{index + 1 !== journey.length && <TimelineConnector />}
									</TimelineSeparator>
									<TimelineContent>
										{step.action} {step.device}
									</TimelineContent>
								</TimelineItem>
							))
							}
						</Timeline>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button sx={actionOneButtonStyle} onClick={() => props.setOpen(false)}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
