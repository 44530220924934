import { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import { Button, Card, CardContent, CardHeader, TextField, Select, MenuItem, InputLabel, CardActions, FormControl, Typography, CircularProgress, Box, useMediaQuery, FormHelperText, Tooltip } from "@mui/material";
import { getTenantSettings } from "src/graphql/queries";
import { UserContext } from "src/contexts/UserContext";
import { generateClient, get, post } from "aws-amplify/api";
import { actionTwoButtonStyle, cardStyle, actionOneButtonStyle } from "src/theme";
import { fetchAuthSession } from "aws-amplify/auth";
import { updateTenantSettings } from "src/graphql/mutations";


export function RingCX() {
	const client = generateClient();
	const useSingleColumn = useMediaQuery('(max-width:845px)');

	const userContext = useContext(UserContext);

	const [reachCXSettings, setReachCXSettings] = useState({
		clientId: '',
		clientSecret: '',
		serverUrl: '',
		adminAccountId: '',
		jwt: ''
	});

	const [connectionSuccessful, setConnectionSuccessful] = useState(false);

	// Loads the saved values when the component is first rendered.
	useEffect(() => {
		async function getData() {
			try {
				const graphqlResponse = await client.graphql({
					query: getTenantSettings,
					variables: { id: userContext.tenantId }
				});
				const tenantSettings = graphqlResponse.data.getTenantSettings;
				if (tenantSettings.telephoneProviderInfo && tenantSettings.telephoneProviderInfo.ringCX) {
						setReachCXSettings({
							clientId: tenantSettings.telephoneProviderInfo.ringCX.clientId,
							clientSecret: tenantSettings.telephoneProviderInfo.ringCX.clientSecret,
							serverUrl: tenantSettings.telephoneProviderInfo.ringCX.serverUrl,
							adminAccountId: tenantSettings.telephoneProviderInfo.ringCX.adminAccountId,
							jwt: tenantSettings.telephoneProviderInfo.ringCX.jwt
						});
				}
			} catch (error) {
				console.error('Error loading RingCX tenant settings', error);
				enqueueSnackbar('An unexpected error occurred while loading your RingCX settings.', {
					variant: 'error',
					autoHideDuration: 5000
				});
			}
		}
		if (userContext.telephonyProvider === 'RingCX') {
			getData();
		}
	}, [userContext.telephonyProvider]);

	async function testConnection(values) {
		try {
			const testResult = await post({
				apiName: 'cdyxoutreach',
				path: '/cci/ringcx/test-connection',
				options: {
					headers: {
						'x-api-key': userContext.apiKey,
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`
					},
					body: {
						...values
					}
				}}).response;

			console.log(await testResult.body.json());
			setConnectionSuccessful(true);

		} catch (error) {
			console.log('Error testing RingCX connection', error);
			enqueueSnackbar('Unable to connect to RingCX. Please check your connection settings.', {
				variant: 'error',
				autoHideDuration: 5000
			});

		}
	}

	async function saveConnection(values) {
		try {
			await client.graphql({
				query: updateTenantSettings,
				variables: {
					input: {
						id: userContext.tenantId,
						telephonyProvider: 'RingCX',
						telephoneProviderInfo: {
							ringCX: {
								clientId: values.clientId,
								clientSecret: values.clientSecret,
								serverUrl: values.serverUrl,
								jwt: values.jwt,
								adminAccountId: values.adminAccountId
							}
						}
					}
				}
			});

		} catch (err) {
			console.error('Error saving RingCX settings', err);
			enqueueSnackbar('An unexpected error occurred while saving your RingCX settings.', {
				variant: 'error',
				autoHideDuration: 5000
			});
		}
	}



	return (
		<>
			{/* RingCX */}
			<Formik
				initialValues={reachCXSettings}
				enableReinitialize={true}
				onSubmit={values => {
					saveConnection(values);
				}}
			>
				{formikProps => (
					<Card
						style={cardStyle}
						sx={{ marginBottom: '24px' }}
						elevation={0}
					>
						<CardHeader title='RingCX' />

						<CardContent>
							<form onSubmit={formikProps.handleSubmit}>
								<Box display="flex" flexDirection="column" gap={2}>
									<Box
										display="grid"
										gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr 1fr 1fr"}
										gap={2}
									>
										<TextField
											fullWidth
											label="Client ID"
											name="clientId"
											value={formikProps.values.clientId}
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											required
										/>
										<TextField
											fullWidth
											label="Client Secret"
											name="clientSecret"
											value={formikProps.values.clientSecret}
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											required
											type="password"
										/>
										<TextField
											fullWidth
											label="Server URL"
											name="serverUrl"
											value={formikProps.values.serverUrl}
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											required
										/>
										<TextField
											fullWidth
											label="Sub Account ID"
											name="adminAccountId"
											value={formikProps.values.adminAccountId}
											onChange={formikProps.handleChange}
											onBlur={formikProps.handleBlur}
											required
										/>
									</Box>
									<TextField
										multiline
										fullWidth
										label="JWT"
										name="jwt"
										value={formikProps.values.jwt}
										onChange={formikProps.handleChange}
										onBlur={formikProps.handleBlur}
										required
									/>
								</Box>
							</form>
						</CardContent>
						<CardActions>
							<Box
								width="100%"
								display="flex"
								flexDirection="row"
								justifyContent="end"
								alignItems="center">
								<Button sx={actionTwoButtonStyle} onClick={() => testConnection(formikProps.values)}>Test Connection</Button>
								<Button disabled={!connectionSuccessful} sx={actionOneButtonStyle} onClick={formikProps.handleSubmit}>Save</Button>
							</Box>
						</CardActions>
					</Card>
				)}
			</Formik>
		</>
	);
}
