import { useEffect, useState, Fragment } from 'react';
import { Divider, withAuthenticator } from '@aws-amplify/ui-react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {
	// AppBar,
	Button,
	// IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Collapse,
	ListSubheader,
	Box,
	CssBaseline,
	Avatar,
	IconButton,
	Tooltip,
	AppBar,
	Toolbar,
	Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { Outlet, Link } from 'react-router-dom';
import {
	EmailOutlined,
	HelpOutlined,
	SupervisorAccountOutlined,
	Inventory2Outlined,
	PeopleOutlined,
	ConnectWithoutContactOutlined,
	RecentActorsOutlined,
	DoNotTouchOutlined,
	CampaignOutlined,
	AdsClickOutlined,
	InsertChartOutlined,
	ExpandLess,
	ExpandMore,
	LeakAddOutlined,
	FiberManualRecordOutlined,
	LogoutOutlined,
	MenuOutlined,
	PhoneCallbackOutlined,
	// Brightness4Outlined,
	// Brightness7Outlined,
} from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { UserContext, loadContext } from './contexts/UserContext';
import { fetchAuthSession } from '@aws-amplify/core';
import { formFields, components } from './cognito-authentication';
import {
	listItemButtonStyle,
	listItemIconSelectedStyle,
	listItemIconStyle,
	listItemSelectedStyle,
	subListItemButtonStyle,
	subListItemSelectedStyle,
} from './theme';
import { InContact } from './contexts/InContact';

function MainLayout({
	signOut,
	// themeMode,
	// setThemeMode
}) {
	const [userContext, setUserContext] = useState({
		tenant: '',
		tenantRole: '',
		company: '',
		name: '',
		apiKey: '',
		licenses: [],
		customFields: [],
		queryFields: [],
		rights: [],
		telephonyProvider: '',
	});

	// const [open, setOpen] = useState(false);
	// const [actions, setActions] = useState([]);

	const [userBtn, setUserBtn] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [dataExportOpen, setDataExportOpen] = useState(false);
	const [contactsOpen, setContactsOpen] = useState(false);
	const [managementOpen, setManagementOpen] = useState(false);
	const [suppressionOpen, setSuppressionOpen] = useState(false);
	const [omniOpen, setOmniOpen] = useState(false);
	const [connectionOpen, setConnectionOpen] = useState(false);
	const [drawerVariant, setDrawerVariant] = useState('permanent');
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [miniDrawer, setMiniDrawer] = useState(false);
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	const [drawerWidth, setDrawerWidth] = useState(315);

	useEffect(() => {
		const handleResize = () => {
			setScreenSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		if (screenSize.width < 768) {
			setDrawerVariant('temporary');
		} else {
			setDrawerVariant('permanent');
		}

		window.addEventListener('resize', handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [screenSize.width]);

	useEffect(() => {
		if (miniDrawer) {
			setDrawerWidth(75);
		} else {
			setDrawerWidth(315);
		}
	}, [miniDrawer]);

	useEffect(() => {
		// set active states based on location
		const pathname = location.pathname;
		switch (pathname) {
			case '/contacts':
				setContactsOpen(true);
				break;
			case '/custom-fields':
				setContactsOpen(true);
				break;
			case '/profiles':
				setProfileSelected(true);
				break;
			case '/field-mappings':
				setManagementOpen(true);
				break;
			case '/contact-import':
				setManagementOpen(true);
				break;
			case '/bulk-edit':
				setManagementOpen(true);
				break;
			case '/segments':
				setManagementOpen(true);
				break;
			case '/sftp-export':
				setDataExportOpen(true);
				break;
			case '/activity-export':
				setDataExportOpen(true);
				break;
			default:
				break;

		}
	}, [location]);

	// const [sftpExportSelected, setSftpExportSelected] = useState(false);
	// const [activityExportSelected, setActivityExportSelected] = useState(false);
	const [customFieldsSelected, setCustomFieldsSelected] = useState(false);
	const [contactInventorySelected, setContactInventorySelected] =
		useState(false);
	const [profileSelected, setProfileSelected] = useState(false);
	// const [fieldMappingsSelected, setFieldMappingsSelected] = useState(false);
	// const [contactImportSelected, setContactImportSelected] = useState(false);
	// const [bulkEditSelected, setBulkEditSelected] = useState(false);
	const [contactSegmentationSelected, setContactSegmentationSelected] = useState(
		false
	);

	useEffect(() => {
		// set open states based on location
		const pathname = location.pathname;
		switch (pathname) {
			case '/sftp-export':
				setDataExportOpen(true);
				// setSftpExportSelected(true);
				break;
			case '/activity-export':
				setDataExportOpen(true);
				// setActivityExportSelected(true);
				break;
			case '/custom-fields':
				setContactsOpen(true);
				setCustomFieldsSelected(true);
				break;
			case '/contacts':
				setContactsOpen(true);
				setContactInventorySelected(true);
				break;
			case '/profiles':
				setProfileSelected(true);
				break;
			case '/field-mappings':
				setManagementOpen(true);
				// setFieldMappingsSelected(true);
				break;
			case '/contact-import':
				setManagementOpen(true);
				// setContactImportSelected(true);
				break;
			case '/bulk-edit':
				setManagementOpen(true);
				// setBulkEditSelected(true);
				break;
			case '/segments':
				setManagementOpen(true);
				setContactSegmentationSelected(true);
				break;
			default:
				break;
		}
	}, [location]);

	function stringToColor(string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}

	function stringAvatar(name, size) {
		const firstName = name.split(' ')[0];
		const lastName = name.split(' ')[1];

		let initials;

		if (firstName && lastName) {
			initials = `${firstName[0]}${lastName[0]}`;
		} else {
			initials = name[0];
		}


		return {
			sx: {
				bgcolor: stringToColor(name),
				width: size,
				height: size,
				fontSize: size * 0.5,
			},
			children: initials,
		};
	}

	useEffect(() => {
		async function getData() {
			try {
				const session = await fetchAuthSession();
				const idToken = session.tokens?.idToken;
				const tenantId = idToken.payload['custom:tenant'];
				const { tenant, queryFields } = await loadContext(tenantId);
				const rights = idToken.payload['custom:securityRights'].split('|');

				let context = {
					tenantId: idToken.payload['custom:tenant'],
					tenantRole: idToken.payload['custom:tenantRole'],
					company: idToken.payload['custom:company'],
					name: idToken.payload['name'],
					apiKey: tenant.apiKey,
					customFields: tenant.customFields,
					queryFields: queryFields,
					licenses: tenant.licenses,
					rights: rights,
					telephonyProvider: tenant.telephonyProvider,
					skillLabel: '',
					dispositionLabel: '',
					country: tenant.country,
				};

				switch (context.telephonyProvider) {
					case 'NiC':
						InContact.icSettings = tenant;
						context.skillLabel = 'Skill';
						context.dispositionLabel = 'Disposition';
						break;
					case 'Webex':
						context.skillLabel = 'Queue';
						context.dispositionLabel = 'Wrap Up Code';
						break;
				}

				setUserContext(context);
			} catch (error) {
				console.error(error);
			}
		}
		getData();
	}, []);

	const openHelp = () => {
		window.open(`${location.pathname}.pdf`);
	};

	const handleOpenUserMenu = (event) => {
		setUserBtn(event.currentTarget);
	};

	const handleCloseUser = () => {
		setUserBtn(null);
	};

	const handleContactUs = () => {
		window.location.href = 'mailto:support@customerDynamics.com';
	};

	const handleSignOut = () => {
		setUserBtn(null);
		signOut();
		navigate('/');
	};

	const handleMenuClick = () => {
		if (drawerVariant === 'temporary') {
			setDrawerOpen(!drawerOpen);
		} else if (drawerVariant === 'permanent') {
			setMiniDrawer(!miniDrawer);
		}
	};

	// const handleThemeChange = () => {
	// 	if (themeMode === 'light') {
	// 		setThemeMode('dark');
	// 	} else {
	// 		setThemeMode('light');
	// 	}
	// };

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				elevation={0}
				color="white"
				sx={
					drawerVariant === 'permanent'
						? { width: `calc(100% - ${drawerWidth}px)` }
						: { width: '100%' }
				}
			>
				<Toolbar>
					{drawerVariant === 'temporary' && (
						<Box
							sx={{
								display: 'block',
								padding: '8px',
							}}
						>
							<a href="/">
								<img
									src="/logo.svg"
									alt="Velocity Logo"
									style={{ width: '24px', height: 'auto' }}
								/>
							</a>
						</Box>
					)}
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleMenuClick}
						sx={{ mr: 2 }}
					>
						{/* <MenuIcon /> */}
						<MenuOutlined />
					</IconButton>
					{userContext.name && (
						<Button
							variant="text"
							aria-haspopup="true"
							onClick={handleOpenUserMenu}
							color="inherit"
							sx={{
								marginLeft: 'auto',
							}}
						>
							<Avatar {...stringAvatar(userContext.name, 35)} />
						</Button>
					)}
					<Menu
						id="userMenu"
						anchorEl={userBtn}
						keepMounted
						open={!!userBtn}
						onClose={handleCloseUser}
					>
						<Box sx={{ padding: '24px' }}>
							<Typography variant="h6">User Profile</Typography>
							{userContext.name && (
								<Box
									sx={{
										display: 'flex',
										direction: 'row',
									}}
								>
									<Avatar
										sx={{
											height: '25px',
											width: '25px',
										}}
										{...stringAvatar(userContext.name, 65)}
									/>
									<Box
										sx={{
											marginLeft: '12px',
										}}
									>
										<Typography
											variant="h6"
											sx={{
												textAlign: 'center',
												margin: '8px 0',
											}}
										>
											{userContext.name}
										</Typography>
										<Typography
											variant="body2"
											sx={{
												textAlign: 'center',
												margin: '8px 0',
											}}
										>
											{userContext.company}
										</Typography>
									</Box>
								</Box>
							)}
							<Divider />
							{userContext.tenantRole === 'SuperAdmin' && (
								<MenuItem
									component={NavLink}
									to={'/admin'}
									onClick={handleCloseUser}
								>
									<ListItemIcon>
										<SupervisorAccountOutlined />
									</ListItemIcon>
									Admin
								</MenuItem>
							)}

							<MenuItem onClick={handleContactUs}>
								<ListItemIcon>
									<EmailOutlined />
								</ListItemIcon>
								Contact support
							</MenuItem>
							<MenuItem onClick={openHelp}>
								<ListItemIcon>
									<HelpOutlined />
								</ListItemIcon>
								Help documentation
							</MenuItem>
							{/* <MenuItem onClick={handleThemeChange}>
								<ListItemIcon>
									{themeMode === 'light' ? (
										<Brightness7Outlined />
									) : (
										<Brightness4Outlined />
									)}
								</ListItemIcon>
								{themeMode === 'light' ? 'Light Mode' : 'Dark Mode'}
							</MenuItem> */}
							<Button
								onClick={handleSignOut}
								startIcon={<LogoutOutlined />}
								variant="outlined"
								color="primary"
								sx={{ width: '100%', marginTop: '12px' }}
							>
								Logout
							</Button>
						</Box>
					</Menu>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant={drawerVariant}
				anchor="left"
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
			>
				{!miniDrawer && (
					<Box
						sx={{
							display: 'block',
							margin: '24px 24px 0 24px',
						}}
					>
						<a href="/">
							<img
								src="/velocity_horizontal.svg"
								alt="Velocity Logo"
								style={{ width: '100%', height: 'auto' }}
							/>
						</a>
					</Box>
				)}
				{miniDrawer && (
					<Box
						sx={{
							display: 'block',
							margin: '12px 12px 0 12px',
						}}
					>
						<a href="/">
							<img
								src="/logo.svg"
								alt="Velocity Logo"
								style={{ width: '100%', height: 'auto' }}
							/>
						</a>
					</Box>
				)}
				<SimpleBar style={{ maxHeight: 'calc(100vh - 120px)' }}>
					{/* Make padding 12px when mini */}
					<Box sx={{ overflow: 'auto', padding: miniDrawer ? '12px' : '24px' }}>
						<List>
							<ListSubheader sx={{ fontWeight: 700 }}>
								{miniDrawer ? '...' : 'INVENTORY'}
							</ListSubheader>

							<Fragment>
								<ListItem key={'contacts'} disablePadding>
									<ListItemButton
										sx={
											contactsOpen ? listItemSelectedStyle : listItemButtonStyle
										}
										onClick={() => setContactsOpen(!contactsOpen)}
									>
										<ListItemIcon
											//reduce
											sx={
												contactsOpen
													? { ...listItemIconSelectedStyle }
													: { ...listItemIconStyle }
											}
										>
											{miniDrawer ? (
												<Tooltip title="Contacts" placement="right" arrow>
													<PeopleOutlined />
												</Tooltip>
											) : (
												<PeopleOutlined />
											)}
										</ListItemIcon>
										{!miniDrawer && <ListItemText primary={'Contacts'} />}
										{!miniDrawer &&
											(contactsOpen ? <ExpandLess /> : <ExpandMore />)}
									</ListItemButton>
								</ListItem>
								<Collapse in={contactsOpen} timeout="auto" unmountOnExit>
									<List>
										<ListItem disablePadding>
											<ListItemButton
												sx={ location.pathname === '/contacts' ? subListItemSelectedStyle : subListItemButtonStyle }
												component={Link}
												to={'/contacts'}
											>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Contact Inventory"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Contact Inventory'} />
												)}
											</ListItemButton>
										</ListItem>
										<ListItem disablePadding>
											<ListItemButton
												sx={ location.pathname === '/custom-fields' ? subListItemSelectedStyle : subListItemButtonStyle }
												component={Link}
												to={'/custom-fields'}
											>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Custom Fields"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Custom Fields'} />
												)}
											</ListItemButton>
										</ListItem>

									</List>
								</Collapse>
							</Fragment>

							<Fragment>
								<ListItem key={'management'} disablePadding>
									<ListItemButton
										sx={
											managementOpen
												? listItemSelectedStyle
												: listItemButtonStyle
										}
										onClick={() => setManagementOpen(!managementOpen)}
									>
										<ListItemIcon
											sx={
												managementOpen
													? listItemIconSelectedStyle
													: listItemIconStyle
											}
										>
											{miniDrawer ? (
												<Tooltip title="Management" placement="right" arrow>
													<Inventory2Outlined />
												</Tooltip>
											) : (
												<Inventory2Outlined />
											)}
										</ListItemIcon>
										{!miniDrawer && <ListItemText primary={'Management'} />}
										{!miniDrawer &&
											(managementOpen ? <ExpandLess /> : <ExpandMore />)}
									</ListItemButton>
								</ListItem>
								<Collapse in={managementOpen} timeout="auto" unmountOnExit>
									<List>
										<ListItem disablePadding>
											<ListItemButton
												sx={ location.pathname === '/contact-import' ? subListItemSelectedStyle : subListItemButtonStyle }
												component={Link}
												to={'/contact-import'}
											>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Contact Import"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Contact Import'} />
												)}
											</ListItemButton>
										</ListItem>
										<ListItem disablePadding>
											<ListItemButton sx={ location.pathname === '/bulk-edit' ? subListItemSelectedStyle : subListItemButtonStyle }
												component={Link}
												to={'/bulk-edit'}>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Bulk Edit/Removal"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Bulk Edit/Removal'} />
												)}
											</ListItemButton>
										</ListItem>
										<ListItem disablePadding>
											<ListItemButton sx={ location.pathname === '/segments' ? subListItemSelectedStyle : subListItemButtonStyle }
												component={Link}
												to={'/segments'}>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Contact Segmentation"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Contact Segmentation'} />
												)}
											</ListItemButton>
										</ListItem>
										<ListItem disablePadding>
											<ListItemButton
												sx={location.pathname === '/field-mappings' ? subListItemSelectedStyle : subListItemButtonStyle}
												component={Link}
												to={'/field-mappings'}
											>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Field Mappings"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Field Mappings'} />
												)}
											</ListItemButton>
										</ListItem>
									</List>
								</Collapse>
							</Fragment>

							<ListSubheader sx={{ fontWeight: 700 }}>
								{miniDrawer ? '...' : 'CUSTOMER ENGAGEMENT'}
							</ListSubheader>

							<ListItem disablePadding>
								<ListItemButton
									sx={location.pathname === '/campaigns' ? listItemSelectedStyle : listItemButtonStyle}
									component={Link}
									to={'/campaigns'}
								>
									<ListItemIcon
										sx={location.pathname === '/campaigns' ? listItemIconSelectedStyle : listItemIconStyle}
									>
										{miniDrawer ? (
											<Tooltip title="Campaigns" placement="right" arrow>
												<CampaignOutlined />
											</Tooltip>
										) : (
											<CampaignOutlined />
										)}
									</ListItemIcon>
									{!miniDrawer && <ListItemText primary={'Campaigns'} />}
								</ListItemButton>
							</ListItem>

							<ListItem disablePadding>
								<ListItemButton
									sx={location.pathname === '/profiles' ? listItemSelectedStyle : listItemButtonStyle}
									component={Link}
									to={'/profiles'}
								>
									<ListItemIcon
										sx={location.pathname === '/profiles' ? listItemIconSelectedStyle : listItemIconStyle}
									>
										{miniDrawer ? (
											<Tooltip
												title="Communication Profiles"
												placement="right"
												arrow
											>
												<RecentActorsOutlined />
											</Tooltip>
										) : (
											<RecentActorsOutlined />
										)}
									</ListItemIcon>
									{!miniDrawer && (
										<ListItemText primary={'Communication Profiles'} />
									)}
								</ListItemButton>
							</ListItem>

							<Fragment>
								<ListItem key={'omni'} disablePadding>
									<ListItemButton
										sx={omniOpen ? listItemSelectedStyle : listItemButtonStyle}
										onClick={() => setOmniOpen(!omniOpen)}
									>
										<ListItemIcon
											sx={
												omniOpen ? listItemIconSelectedStyle : listItemIconStyle
											}
										>
											{miniDrawer ? (
												<Tooltip title="Digital Channels" placement="right" arrow>
													<ConnectWithoutContactOutlined />
												</Tooltip>
											) : (
												<ConnectWithoutContactOutlined />
											)}
										</ListItemIcon>
										{!miniDrawer && <ListItemText primary={'Digital Channels'} />}
										{!miniDrawer &&
											(omniOpen ? <ExpandLess /> : <ExpandMore />)}
									</ListItemButton>
								</ListItem>
								<Collapse in={omniOpen} timeout="auto" unmountOnExit>
									<List>
										<ListItem disablePadding>
											<ListItemButton
												sx={location.pathname === '/message-templates' ? subListItemSelectedStyle : subListItemButtonStyle}
												component={Link}
												to={'/message-templates'}
											>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Message Templates"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Message Templates'} />
												)}
											</ListItemButton>
										</ListItem>
										<ListItem disablePadding>
											<ListItemButton sx={location.pathname === '/message-logs' ? subListItemSelectedStyle : subListItemButtonStyle}
												component={Link}
												to={'/digital-profiles'}>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Digital Profiles"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Digital Profiles'} />
												)}
											</ListItemButton>
										</ListItem>
									</List>
								</Collapse>
							</Fragment>

							<Fragment>
								<ListItem key={'suppression'} disablePadding>
									<ListItemButton
										sx={
											suppressionOpen
												? listItemSelectedStyle
												: listItemButtonStyle
										}
										onClick={() => setSuppressionOpen(!suppressionOpen)}
									>
										<ListItemIcon
											sx={
												suppressionOpen
													? listItemIconSelectedStyle
													: listItemIconStyle
											}
										>
											{miniDrawer ? (
												<Tooltip title="Suppression" placement="right" arrow>
													<DoNotTouchOutlined />
												</Tooltip>
											) : (
												<DoNotTouchOutlined />
											)}
										</ListItemIcon>
										{!miniDrawer && <ListItemText primary={'Suppression'} />}
										{!miniDrawer &&
											(suppressionOpen ? <ExpandLess /> : <ExpandMore />)}
									</ListItemButton>
								</ListItem>
								<Collapse in={suppressionOpen} timeout="auto" unmountOnExit>
									<List>
										<ListItem disablePadding>
											<ListItemButton
												sx={location.pathname === '/suppression' ? subListItemSelectedStyle : subListItemButtonStyle}
												component={Link}
												to={'/suppression'}
											>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Suppression Profiles"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Suppression Profiles'} />
												)}
											</ListItemButton>
										</ListItem>
										<ListItem disablePadding>
											<ListItemButton
												sx={location.pathname === '/dnc-lists' ? subListItemSelectedStyle : subListItemButtonStyle}
												component={Link}
												to={'/dnc-lists'}>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip title="DNC" placement="right" arrow>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && <ListItemText primary={'DNC'} />}
											</ListItemButton>
										</ListItem>
										<ListItem disablePadding>
											<ListItemButton
												sx={location.pathname === '/contact-field-suppression' ? subListItemSelectedStyle : subListItemButtonStyle}
												component={Link}
												to={'/contact-field-suppression'}>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Contact Data Suppression"
															placement="right"
															arrow
														></Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Contact Data Suppression'} />
												)}
											</ListItemButton>
										</ListItem>
									</List>
								</Collapse>


							{userContext.telephonyProvider !== 'Webex' && <ListItem disablePadding>
								<ListItemButton sx={listItemButtonStyle}
									component={Link}
									to={'/approver'}
								>
									<ListItemIcon sx={listItemIconStyle}>
										{miniDrawer ? (
											<Tooltip
												title="Live Agent Select"
												placement="right"
												arrow
											>
												<AdsClickOutlined />
											</Tooltip>
										) : (
											<AdsClickOutlined />
										)}
									</ListItemIcon>
									{!miniDrawer && (
										<ListItemText primary={'Live Agent Select'} />
									)}
								</ListItemButton>
							</ListItem>}
							</Fragment>

							{/* <ListSubheader sx={{ fontWeight: 700 }}>
								{miniDrawer ? '...' : 'DASHBOARDS & REPORTING'}
							</ListSubheader>
							<Fragment>
								<ListItem key={'dashboards'} disablePadding>
									<ListItemButton
										onClick={() => setDataExportOpen(!dataExportOpen)}
										sx={
											dataExportOpen
												? listItemSelectedStyle
												: listItemButtonStyle
										}
									>
										<ListItemIcon
											sx={
												dataExportOpen
													? listItemIconSelectedStyle
													: listItemIconStyle
											}
										>
											{miniDrawer ? (
												<Tooltip title="Data Export" placement="right" arrow>
													<InsertChartOutlined />
												</Tooltip>
											) : (
												<InsertChartOutlined />
											)}
										</ListItemIcon>
										{!miniDrawer && <ListItemText primary={'Data Export'} />}
										{!miniDrawer &&
											(dataExportOpen ? <ExpandLess /> : <ExpandMore />)}
									</ListItemButton>
								</ListItem>
								<Collapse in={dataExportOpen} timeout="auto" unmountOnExit>
									<List>
										<ListItem disablePadding>
											<ListItemButton sx={subListItemButtonStyle}>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="SFTP Export"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'SFTP Export'} />
												)}
											</ListItemButton>
										</ListItem>
										<ListItem disablePadding>
											<ListItemButton sx={subListItemButtonStyle}>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Activity Export"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Activity Export'} />
												)}
											</ListItemButton>
										</ListItem>
									</List>
								</Collapse>
							</Fragment> */}

							<ListSubheader sx={{ fontWeight: 700 }}>
								{miniDrawer ? '...' : 'CONTACT CENTER'}
							</ListSubheader>

							<Fragment>
								<ListItem key={'connection'} disablePadding>
									<ListItemButton
										sx={
											connectionOpen
												? listItemSelectedStyle
												: listItemButtonStyle
										}
										onClick={() => setConnectionOpen(!connectionOpen)}
									>
										<ListItemIcon
											sx={
												connectionOpen
													? listItemIconSelectedStyle
													: listItemIconStyle
											}
										>
											{miniDrawer ? (
												<Tooltip title="Connection" placement="right" arrow>
													<LeakAddOutlined />
												</Tooltip>
											) : (
												<LeakAddOutlined />
											)}
										</ListItemIcon>
										{!miniDrawer && <ListItemText primary={'Connection'} />}
										{!miniDrawer &&
											(connectionOpen ? <ExpandLess /> : <ExpandMore />)}
									</ListItemButton>
								</ListItem>
								<Collapse in={connectionOpen} timeout="auto" unmountOnExit>
									<List>
										<ListItem disablePadding>
											<ListItemButton
												sx={location.pathname === '/connection-details' ? subListItemSelectedStyle : subListItemButtonStyle}
												component={Link}
												to={'/connection-details'}
											>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Connection Details"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Connection Details'} />
												)}
											</ListItemButton>
										</ListItem>
										<ListItem disablePadding>
											<ListItemButton
												sx={location.pathname === '/disposition-mappings' ? subListItemSelectedStyle : subListItemButtonStyle}
												component={Link}
												to={'/disposition-mappings'}>
												<ListItemIcon>
													{miniDrawer ? (
														<Tooltip
															title="Disposition Mappings"
															placement="right"
															arrow
														>
															<FiberManualRecordOutlined fontSize="small" />
														</Tooltip>
													) : (
														<FiberManualRecordOutlined fontSize="small" />
													)}
												</ListItemIcon>
												{!miniDrawer && (
													<ListItemText primary={'Disposition Mappings'} />
												)}
											</ListItemButton>
										</ListItem>
									</List>
								</Collapse>
							</Fragment>
						</List>
					</Box>
				</SimpleBar>
			</Drawer>
			{/* Offset the main content to account for app bar */}
			<Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '64px' }}>
				<UserContext.Provider value={userContext}>
					<Outlet />
				</UserContext.Provider>
			</Box>
		</Box>
	);
}

export const AuthenticatedLayout = withAuthenticator(MainLayout, {
	hideSignUp: true,
	formFields,
	components,
});
