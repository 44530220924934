import {
	Box,
	Card,
	CircularProgress,
	Grid,
	Paper,
	Popper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { CAMPAIGN_COLORS } from '../../models/campaignColors';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { I18n } from 'aws-amplify/utils';

/** The amount of time between refreshes in milliseconds. */
const CAMPAIGN_STATUS_REFRESH_INTERVAL = 10000;

const STRINGS = {
	'en-US': { // English (United States)
		infoHeading: 'Info',
		gettingContactsMessage: 'Getting contacts...',
		totalContacts: 'Total Contacts',
		needsApproval: 'Needs Approval',
		completed: 'Completed',
		percentage: 'Percentage',
		segmentsHeading: 'Segments',
		segmentHeading: 'Segment',
		fresh: 'Fresh',
		availableTotal: 'Available / Total',
		retry: 'Retry',
		readyTotal: 'Ready / Total',
		noSegmentStats: 'No segment stats',
		campaign: 'Campaign',
	},
	'es-419': { // Spanish (Latin America)
		infoHeading: 'Información',
		gettingContactsMessage: 'Obteniendo contactos...',
		totalContacts: 'Contactos Totales',
		needsApproval: 'Necesita Aprobación',
		completed: 'Completados',
		percentage: 'Porcentaje',
		segmentsHeading: 'Segmentos',
		segmentHeading: 'Segmento',
		fresh: 'Fresco',
		availableTotal: 'Disponible / Total',
		retry: 'Reintentar',
		readyTotal: 'Listo / Total',
		noSegmentStats: 'Sin estadísticas de segmento',
		campaign: 'Campaña',
	},
	'pt-BR': { // Portuguese (Brazil)
		infoHeading: 'Informações',
		gettingContactsMessage: 'Obtendo contatos...',
		totalContacts: 'Contatos Totais',
		needsApproval: 'Precisa de Aprovação',
		completed: 'Completados',
		percentage: 'Porcentagem',
		segmentsHeading: 'Segmentos',
		segmentHeading: 'Segmento',
		fresh: 'Fresco',
		availableTotal: 'Disponível / Total',
		retry: 'Repetir',
		readyTotal: 'Pronto / Total',
		noSegmentStats: 'Sem estatísticas de segmento',
		campaign: 'Campanha',
	}
};
I18n.putVocabularies(STRINGS);

const fadeInStyles = {
	animation: 'fadeIn 0.25s',
	'@keyframes fadeIn': {
		'0%': {
			opacity: 0,
		},
		'100%': {
			opacity: 1,
		},
	},
};

const statusItemStyles = {
	color: 'white',
	borderRadius: '15px',
	textAlign: 'center',
	padding: '10px',
};

const arrowStyles = {
	width: '0',
	height: '0',
	borderLeft: '6px solid transparent',
	borderRight: '6px solid transparent',
	borderBottom: '6px solid #fff',
	position: 'absolute',
	top: '-6px',
	...fadeInStyles,
};

/**
 *
 * @param {{
 *  click2Call: boolean,
 *  showStatus: boolean,
 *  multipleCampaignsSelected: boolean,
 *  gettingContacts: boolean,
 *  selectedCampaigns: Array<object>
 *  selectedChannel: string,
 *  availableAgentsPerCampaign: Map<string, number>,
 *  leaderboardPerCampaign: Map<string, Array<any>>,
 * }} props
 */
export function CampaignInfoDrawer(props) {
	const userContext = useContext(UserContext);
	const theme = useTheme();

	/** The list of campaign statuses for all of the selected campaigns. */
	const [campaignStatuses, setCampaignStatuses] = useState([]);

	const [campaignStatusPerCampaign, setCampaignStatusPerCampaign] = useState(
		new Map(),
	);

	/** The number of total contacts across all selected campaigns. */
	const [totalNumberOfContacts, setTotalNumberOfContacts] = useState(0);

	/** The number of contacts that have been completed across all selected campaigns. */
	const [totalNumberOfCompletedContacts, setTotalNumberOfCompletedContacts] =
		useState(0);

	/** The percentage of contacts completed across all selected campaigns. */
	const [percentageOfCompletedContacts, setPercentageOfCompletedContacts] =
		useState(0);

	/** The number of contacts that need approval across all selected campaigns. */
	const [
		totalNumberOfContactsNeedingApproval,
		setTotalNumberOfContactsNeedingApproval,
	] = useState(0);

	/** The number of agents that are available across all selected campaigns. */
	const [totalNumberOfAgentsAvailable, setTotalNumberOfAgentsAvailable] =
		useState(0);

	/** The number of calls in progress across all selected campaigns. */
	const [totalNumberOfCallsInProgress, setTotalNumberOfCallsInProgress] =
		useState(0);

	/** The number of SMS texts sent across all selected campaigns. */
	const [totalNumberOfSmsSent, setTotalNumberOfSmsSent] = useState(0);

	/** The agent scores across all selected campaigns. */
	const [agentScores, setAgentScores] = useState([]);

	/** The element that triggered the hover. Used to anchor the popup to it. */
	const [popupAnchorElement, setPopupAnchorElement] = useState(null);

	/** The status property that is being hovered. */
	const [hoveredStatusProperty, setHoveredStatusProperty] = useState('');

	/** The arrow element for the popup. */
	const [arrowElement, setArrowElement] = useState(null);

	/**
	 * Updates the campaign statuses to refresh whenever the selected campaigns or channel change.
	 */
	useEffect(() => {
		let campaignStatusInterval;

		// Stop refreshing if there are no campaigns selected
		if (!props.selectedCampaigns.length) {
			clearInterval(campaignStatusInterval);
			return;
		}

		// Refresh the campaign statuses
		refreshCampaignStatuses();
		campaignStatusInterval = setInterval(async () => {
			refreshCampaignStatuses();
		}, CAMPAIGN_STATUS_REFRESH_INTERVAL);

		return () => {
			clearInterval(campaignStatusInterval);
		};
	}, [props.selectedCampaigns, props.selectedChannel]);

	/**
	 * Calculate the new agents available whenever it changes for a campaign.
	 */
	useEffect(() => {
		// TODO: This should be moved to a campaign status message
		const agentsAvailable = Array.from(
			props.availableAgentsPerCampaign.values(),
		).reduce((runningTotal, agents) => runningTotal + agents, 0);
		setTotalNumberOfAgentsAvailable(agentsAvailable);
	}, [props.availableAgentsPerCampaign]);

	/**
	 * Calculate the combined leaderboard any time the leaderboard for a campaign changes.
	 */
	useEffect(() => {
		const newAgentScores = [];

		for (const campaign of props.selectedCampaigns) {
			const campaignId = campaign.id;
			const leaderboard = props.leaderboardPerCampaign.get(campaignId);
			if (leaderboard.length > 0) {
				for (const agent of leaderboard) {
					// NOTE: This assumes that every agent has a unique name
					const existingAgentScoreIndex = newAgentScores.findIndex(
						(existingAgent) => existingAgent.name === agent.name,
					);
					// If the agent is already in the list, add the score to their existing score
					if (existingAgentScoreIndex !== -1) {
						newAgentScores[existingAgentScoreIndex].score += agent.score;
					} else {
						// If the agent is not in the list, add them to the list
						newAgentScores.push({
							...agent,
						});
					}
				}
			}
		}
		const orderedAgentScores = newAgentScores.sort((a, b) => b.score - a.score);
		setAgentScores(orderedAgentScores);
	}, [props.leaderboardPerCampaign]);

	/**
	 * Refreshes the campaign statuses for all of the selected campaigns.
	 */
	async function refreshCampaignStatuses() {
		const campaignStatusPromises = [];
		const newCampaignStatusesPerCampaign = new Map();
		props.selectedCampaigns.forEach((campaign) => {
			campaignStatusPromises.push(
				getCampaignStatus(campaign.id).then((campaignStatus) => {
					newCampaignStatusesPerCampaign.set(campaign.id, campaignStatus);
					return campaignStatus;
				}),
			);
		});

		let newCampaignStatuses = [];
		try {
			newCampaignStatuses = await Promise.all(campaignStatusPromises);
		} catch (error) {
			console.error('Error getting campaign statuses', error);
			return;
		}
		setCampaignStatuses(newCampaignStatuses);
		setCampaignStatusPerCampaign(newCampaignStatusesPerCampaign);

		// Calculate the total number of contacts
		const contactTotal = newCampaignStatuses.reduce(
			(runningTotal, campaignStatus) =>
				runningTotal + campaignStatus.totalContacts,
			0,
		);
		setTotalNumberOfContacts(contactTotal);

		// Calculate the total number of completed contacts
		const segments = newCampaignStatuses
			.map((campaignStatus) => campaignStatus.campaignStats)
			.flat();
		const completedTotal = segments.reduce(
			(runningTotal, segment) => runningTotal + segment.completed,
			0,
		);
		setTotalNumberOfCompletedContacts(completedTotal);

		// Calculate the percentage of contacts completed
		const percentage = (completedTotal / contactTotal) * 100 || 0;
		setPercentageOfCompletedContacts(percentage.toFixed(2));

		// Calculate the number of contacts requiring voice approval
		const needsApprovalProperty =
			props.selectedChannel === 'voice' ? 'needsApproval' : 'smsNeedsApproval';
		const approvalNumber = newCampaignStatuses.reduce(
			(runningTotal, campaignStatus) =>
				runningTotal + campaignStatus[needsApprovalProperty],
			0,
		);
		setTotalNumberOfContactsNeedingApproval(approvalNumber);

		if (!props.click2Call) {
			// Calculate the number of calls in progress
			const totalCalls = newCampaignStatuses.reduce(
				(runningTotal, campaignStatus) =>
					runningTotal + campaignStatus.inProgress,
				0,
			);
			setTotalNumberOfCallsInProgress(totalCalls);
		}

		if (props.selectedChannel === 'sms') {
			// Calculate the number of SMS texts sent
			const totalSms = newCampaignStatuses.reduce(
				(runningTotal, campaignStatus) => runningTotal + campaignStatus.smsSent,
				0,
			);
			setTotalNumberOfSmsSent(totalSms);
		}
	}

	/**
	 * Gets the status of the campaign through an API call.
	 *
	 * @param {string} campaignId The ID of the campaign to get the status of.
	 * @returns {Promise<object>} The status of the campaign.
	 */
	async function getCampaignStatus(campaignId) {
		const response = await post({
			apiName: 'cdyxoutreach',
			path: '/cache/status',
			options: {
				headers: {
					Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
					'x-api-key': userContext.apiKey,
				},
				body: {
					campaignId,
				},
			},
		}).response;

		return await response.body.json();
	}

	/**
	 * Sets the popup element to the element that triggered the hover.
	 *
	 * @param {React.MouseEvent<HTMLSpanElement, MouseEvent>} event The mouse event that triggered the hover.
	 */
	function handleStatusHover(event) {
		if (event.type === 'mouseleave') {
			setPopupAnchorElement(null);
			return;
		}
		setHoveredStatusProperty(event.currentTarget.id);
		setPopupAnchorElement(event.currentTarget);
	}

	/**
	 * Gets the content for the campaign breakdown popup.
	 *
	 * @returns {JSX.Element} The content for the campaign breakdown popup.
	 */
	function getCampaignBreakdownPopupContent() {
		const agentName = hoveredStatusProperty.split('#')[1];
		return (
			<>
				<span
					component={Paper}
					elevation={24}
					sx={arrowStyles}
					ref={setArrowElement}
				/>
				<TableContainer component={Paper} elevation={24} sx={fadeInStyles} color="background">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align="left">{I18n.get('campaign', STRINGS['en-US'].campaign)}</TableCell>
								<TableCell align="right">
									{getCampaignStatusTitle(hoveredStatusProperty)}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.selectedCampaigns.map((campaign, i) => {
								const campaignStatus = campaignStatusPerCampaign.get(
									campaign.id,
								);
								if (!campaignStatus) {
									return null;
								}
								return (
									<TableRow key={campaign.id}>
										<TableCell align="left">
											<div
												style={{
													display: 'inline-block',
													width: '10px',
													height: '10px',
													borderRadius: '50%',
													backgroundColor: CAMPAIGN_COLORS[i],
													marginRight: '5px',
												}}
											></div>
											{campaign.name}
										</TableCell>
										<TableCell align="right">
											{hoveredStatusProperty === 'completed'
												? campaignStatus.campaignStats.reduce(
													(runningTotal, segment) =>
														runningTotal + segment[hoveredStatusProperty],
													0,
												)
												: ''}
											{hoveredStatusProperty === 'percentage'
												? (
													(campaignStatus.campaignStats.reduce(
														(runningTotal, segment) =>
															runningTotal + segment.completed,
														0,
													) /
														campaignStatus.totalContacts) *
													100
												).toFixed(2) + '%'
												: ''}
											{agentName
												? props.leaderboardPerCampaign
													.get(campaign.id)
													.filter(
														(agentScore) => agentScore.name === agentName,
													)[0]?.score
												: ''}
											{hoveredStatusProperty === 'agentsAvailable'
												? props.availableAgentsPerCampaign.get(campaign.id)
												: ''}
											{hoveredStatusProperty !== 'completed' &&
												hoveredStatusProperty !== 'agentsAvailable' &&
												hoveredStatusProperty !== 'percentage' &&
												!agentName
												? campaignStatus[hoveredStatusProperty]
												: ''}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	}

	/**
	 * Gets the title for the campaign status.
	 *
	 * @param {string} statusProperty The status property to get the title for.
	 * @returns {string} The title for the campaign status.
	 */
	function getCampaignStatusTitle(statusProperty) {
		switch (statusProperty) {
			case 'totalContacts':
				return I18n.get('totalContacts', STRINGS['en-US'].totalContacts);
			case 'needsApproval':
			case 'smsNeedsApproval':
				return I18n.get('needsApproval', STRINGS['en-US'].needsApproval);
			case 'completed':
				return I18n.get('completed', STRINGS['en-US'].completed);
			case 'percentage':
				return I18n.get('percentage', STRINGS['en-US'].percentage);
			case 'agentsAvailable':
				return 'Agents Available';
			case 'inProgress':
				return 'In Progress';
			case 'smsSent':
				return 'SMS Sent';
			default:
				return 'Score';
		}
	}

	/**
	 * Generates a list of elements containing call status panes for each approved contact in Click2Call
	 *
	 * @returns A number of CallStatusPane elements within a Grid item.
	 */
	/* TODO: Review and re-enable? */
	// function getMultiCallStatusPanes() {
	//     const panes = [];
	//     for (let i = 0; i < approvedContacts.length; i++) {
	//         panes.push(
	//             <Grid item key={i}>
	//                 <CallStatusPane contact={approvedContacts[i]} onDismiss={removeFromCallApproved}></CallStatusPane>
	//             </Grid>
	//         );
	//     }
	//     return panes;
	// }

	return (
		<Box>
			{/* Multi Approve Status */}
			{/* TODO: Review and re-enable? */}
			{/* {props.click2Call && allowMultiApprove &&
                <Grid container direction='column' justifyContent='space-evenly' alignItems='stretch' spacing={1}>
                    {getMultiCallStatusPanes().map((pane) => {
                        return pane;
                    })}
                </Grid>
            } */}

			{props.selectedCampaigns.length > 0 && (
				<div>
					{/* Campaign Status Header */}
					<Grid style={{ textAlign: 'center', paddingTop: '20px' }}>
						<Typography variant="h5">{I18n.get('infoHeading', STRINGS['en-US'].infoHeading)}</Typography>
					</Grid>

					{/* Activity */}
					<Box
						display="flex"
						gap="10px"
						alignItems="center"
						justifyContent="center"
						height="80px"
					>
						{props.gettingContacts && (
							<>
								<CircularProgress
									variant="indeterminate"
									color="primary"
									size={16}
									sx={fadeInStyles}
								/>
								<Typography variant="body1" sx={fadeInStyles}>
									{I18n.get('gettingContactsMessage', STRINGS['en-US'].gettingContactsMessage)}
								</Typography>
							</>
						)}
					</Box>

					{/* Campaign Status Items */}
					<Grid
						item
						container
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						style={{ marginBottom: '24px' }}
					>
						<Box
							display="grid"
							gridTemplateColumns="1fr 1fr"
							gap="10px"
							width="100%"
						>
							<Box sx={{ ...statusItemStyles, backgroundColor: theme.palette.primary[theme.palette.mode] }}>
								<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
									{I18n.get('totalContacts', STRINGS['en-US'].totalContacts)}
								</Typography>
								<Typography
									id="totalContacts"
									variant="body1"
									onMouseEnter={handleStatusHover}
									onMouseLeave={handleStatusHover}
								>
									{totalNumberOfContacts}
								</Typography>
							</Box>
							<Box sx={{ ...statusItemStyles, backgroundColor: theme.palette.primary[theme.palette.mode] }}>
								<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
									{I18n.get('needsApproval', STRINGS['en-US'].needsApproval)}
								</Typography>
								<Typography
									id={
										props.selectedChannel === 'sms'
											? 'smsNeedsApproval'
											: 'needsApproval'
									}
									variant="body1"
									onMouseEnter={handleStatusHover}
									onMouseLeave={handleStatusHover}
								>
									{totalNumberOfContactsNeedingApproval}
								</Typography>
							</Box>

							<Box sx={{ ...statusItemStyles, backgroundColor: theme.palette.primary[theme.palette.mode] }}>
								<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
									{I18n.get('completed', STRINGS['en-US'].completed)}
								</Typography>
								<Typography
									id="completed"
									variant="body1"
									onMouseEnter={handleStatusHover}
									onMouseLeave={handleStatusHover}
								>
									{totalNumberOfCompletedContacts}
								</Typography>
							</Box>
							<Box sx={{ ...statusItemStyles, backgroundColor: theme.palette.primary[theme.palette.mode] }}>
								<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
									{I18n.get('percentage', STRINGS['en-US'].percentage)}
								</Typography>
								<Typography
									id="percentage"
									variant="body1"
									onMouseEnter={handleStatusHover}
									onMouseLeave={handleStatusHover}
								>
									{percentageOfCompletedContacts}%
								</Typography>
							</Box>
						</Box>

						{!props.click2Call && props.selectedChannel === 'voice' && (
							<Grid item container direction="row">
								<Grid
									item
									style={
										totalNumberOfAgentsAvailable
											? theme.palette.type === 'dark'
												? { color: 'white' }
												: { color: 'black' }
											: { color: 'red' }
									}
								>
									<Typography
										variant="subtitle1"
										style={{ fontWeight: 'bold' }}
									>
										Agents Available
									</Typography>
									<Typography
										id="agentsAvailable"
										variant="body1"
										onMouseEnter={handleStatusHover}
										onMouseLeave={handleStatusHover}
									>
										{totalNumberOfAgentsAvailable}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant="subtitle1"
										style={{ fontWeight: 'bold' }}
									>
										In Progress
									</Typography>
									<Typography
										id="inProgress"
										variant="body1"
										onMouseEnter={handleStatusHover}
										onMouseLeave={handleStatusHover}
									>
										{totalNumberOfCallsInProgress}
									</Typography>
								</Grid>
							</Grid>
						)}
						{props.selectedChannel === 'sms' && (
							<Grid item container direction="row">
								<Grid item>
									<Typography
										variant="subtitle1"
										style={{ fontWeight: 'bold' }}
									>
										SMS Sent
									</Typography>
									<Typography
										id="smsSent"
										variant="body1"
										onMouseEnter={handleStatusHover}
										onMouseLeave={handleStatusHover}
									>
										{totalNumberOfSmsSent}
									</Typography>
								</Grid>
							</Grid>
						)}
					</Grid>

					{/* DEBUG - Approval Group Display */}
					{props.debugApprovalGroupsPerCampaign && (
						<>
							<Card variant="outlined">
								<Typography variant="h6" align="center" color="error">
									Approval Groups (DEBUG)
								</Typography>
								{Array.from(props.debugApprovalGroupsPerCampaign.entries()).map(
									([campaignId, approvalGroups]) => {
										return (
											<React.Fragment key={campaignId}>
												<h3>
													{
														props.selectedCampaigns.find(
															(campaign) => campaign.id === campaignId,
														)?.name
													}
												</h3>
												{approvalGroups.map((approvalGroup, index) => {
													return (
														<React.Fragment key={index}>
															<Typography
																variant="subtitle1"
																style={{ fontWeight: 'bold' }}
																align="center"
															>
																Group {index + 1}
															</Typography>
															<Typography variant="body2" align="center">
																{approvalGroup
																	.map((approver) => approver.name)
																	.join(', ')}
															</Typography>
															<br></br>
														</React.Fragment>
													);
												})}
											</React.Fragment>
										);
									},
								)}
							</Card>
							<br></br>
						</>
					)}

					{/* Segment Table */}
					<Typography variant="h6" align="center">
						{I18n.get('segmentsHeading', STRINGS['en-US'].segmentsHeading)}
					</Typography>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="center">{I18n.get('segmentHeading', STRINGS['en-US'].segmentHeading)}</TableCell>
									<TableCell align="center">
										{I18n.get('fresh', STRINGS['en-US'].fresh)}<br></br>{I18n.get('availableTotal', STRINGS['en-US'].availableTotal)}
									</TableCell>
									<TableCell align="center">
										{I18n.get('retry', STRINGS['en-US'].retry)}<br></br>{I18n.get('readyTotal', STRINGS['en-US'].readyTotal)}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{campaignStatuses.length === 0 && (
									<TableRow>
										<TableCell align="center" colSpan={3}>
											{I18n.get('noSegmentStats', STRINGS['en-US'].noSegmentStats)}
										</TableCell>
									</TableRow>
								)}
								{campaignStatuses.map((campaignStatus) =>
									campaignStatus.campaignStats.map((stat) => (
										<TableRow key={stat.segment}>
											<TableCell align="left" style={{ fontWeight: 'bold' }}>
												{stat.segment}
											</TableCell>
											<TableCell align="center">
												{(props.selectedChannel === 'voice'
													? `${stat.availableFresh} / `
													: '') + stat.fresh}
											</TableCell>
											<TableCell align="center">
												{(props.selectedChannel === 'voice'
													? `${stat.readyRetry} / `
													: '') + stat.retry}
											</TableCell>
											{/* {props.selectedChannel === 'voice' &&
												<>
													<TableCell align='right'>{stat.availableFresh}</TableCell>
													<TableCell align='right'>{stat.readyRetry}</TableCell>
												</>
											} */}
										</TableRow>
									)),
								)}
							</TableBody>
						</Table>
					</TableContainer>

					<br></br>

					{/* Leaderboard */}
					{!props.click2Call && (
						<>
							<Typography variant="h6" align="center">
								Leaderboard
							</Typography>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="left">Agent</TableCell>
											<TableCell align="right">Score</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{agentScores.length === 0 && (
											<TableRow>
												<TableCell align="center" colSpan={2}>
													No scores
												</TableCell>
											</TableRow>
										)}
										{agentScores.map((agentScore) => (
											<TableRow key={agentScore.name}>
												<TableCell align="left">{agentScore.name}</TableCell>
												<TableCell
													align="right"
													id={`score#${agentScore.name}`}
													onMouseEnter={handleStatusHover}
													onMouseLeave={handleStatusHover}
												>
													{agentScore.score}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					)}
				</div>
			)}
			{/* Status Breakdown Popup */}
			<Popper
				open={!!popupAnchorElement && props.selectedCampaigns.length > 1}
				anchorEl={popupAnchorElement}
				placement="bottom"
				modifiers={[
					{
						name: 'arrow',
						options: {
							enabled: !!arrowElement,
							element: arrowElement,
						},
					},
				]}
				style={{ zIndex: 9999 }}
			>
				{getCampaignBreakdownPopupContent()}
			</Popper>
		</Box>
	);
}
