import { createContext } from 'react';
import { generateClient } from 'aws-amplify/api';
import { getTenantSettings } from '../graphql/queries';

//Api Client
const api = generateClient();

/**
 * The tenant context. Set up independently so that the request for the
 * tenant ID only has to be made once per page load.
 */
export const UserContext = createContext({
	tenantId: '',
	tenantRole: '',
	company: '',
	name: '',
	secRole: '',
	icSkills: [],
	secToken: '',
	ftpExport: {},
	licenses: [],
	customFields: [],
	queryFields: [],
	rights: [],
	telephonyProvider: '',
	country: '',
});

/**
 * Loads the tenant context.
 *
 * @param {string} tenantId The tenant ID to load.
 * @returns
 */
export async function loadContext(tenantId) {
	const customFieldTypes = {
		String: 'String',
		Number: 'Number',
		DateTime: 'DateTime',
		Boolean: 'Boolean',
		Phone: 'Phone',
	};

	const response = await api.graphql({
		query: getTenantSettings,
		variables: { id: tenantId },
	});

	const tenant = response.data.getTenantSettings;
	if (!tenant) {
		throw new Error(`Tenant ${tenantId} not found.`);
	}

	const customFields = tenant.customFields;

	let queryFields = {
		list: [
			{ name: 'firstName', label: 'First Name' },
			{ name: 'lastName', label: 'Last Name' },
			{ name: 'phone', label: 'Phone' },
			{ name: 'source', label: 'Source' },
			{ name: 'cell', label: 'Cell' },
			{ name: 'email', label: 'Email' },
			{ name: 'address1', label: 'Address1' },
			{ name: 'address2', label: 'Address2' },
			{ name: 'city', label: 'City' },
			{ name: 'state', label: 'State' },
			{ name: 'zip', label: 'Zip' },
			{ name: 'outboundCallerId', label: 'Outbound Caller ID' },
			{ name: 'createdAt', label: 'Date Created', type: 'date' },
		],
	};
	if (customFields) {
		customFields.forEach((customField) => {
			queryFields.list.push({
				name: `custom:${customField.name}`,
				label: customField.displayName,
				type:
					customField.type === customFieldTypes.DateTime
						? 'epoch'
						: customField.type === customFieldTypes.Boolean
							? 'boolean'
							: 'text',
			});
		});
	}
	queryFields.typeLookup = (field) =>
		queryFields.list.reduce(
			(acc, cur) => Object.assign(acc, { [cur.name]: cur }),
			{},
		)[field]?.type;
	return {
		tenant,
		queryFields,
	};
}

export function isDateField(fieldName) {
	return fieldName.endsWith('Date') || fieldName.toLowerCase().endsWith('dt');
}
