import { useState, useContext, useEffect } from 'react';
import MaterialTable from '@material-table/core'; // { MTableToolbar }
import { useNavigate } from 'react-router-dom';
import {
	// Typography,
	// TablePagination,
	// IconButton,
	// Tooltip,
	// Toolbar,
	Box,
	Card,
	CardHeader,
	CardContent,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import { generateClient } from '@aws-amplify/api';
import { esQuery, simpleContactSearch } from '../../graphql/queries';
import { deleteContact } from '../../graphql/mutations';
import {
	CheckCircleOutline,
	DeleteOutlineOutlined,
	// HelpOutlineOutlined,
	EditOutlined,
	VisibilityOutlined,
} from '@mui/icons-material';
import { UserContext } from '../../contexts/UserContext';
import { ContactDialog } from '../../components/contacts/contactDialog';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { useSnackbar } from 'notistack';

/**
 * The Contact List material table.
 * @component
 */
export function Contacts() {
	const navigate = useNavigate();
	const client = generateClient();
	const userContext = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const [docCount, setDocCount] = useState(0);
	const [totalDocs, setTotalDocs] = useState(0);

	const [searchQuery, setSearchQuery] = useState('');
	const [nextToken, setNextToken] = useState(null);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageTokens, setPageTokens] = useState({});
	const [contactDialogOpen, setContactDialogOpen] = useState(false);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [selectedContact, setSelectedContact] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (userContext.tenantId) {
			loadTotal();
			searchContacts({ page: 0, pageSize: 10 });
		}
	}, [userContext.tenantId]);

	const docCountQuery = {
		"aggs": {
			"dispo": {
				"terms": {
					"field": "tenant.keyword"
				}
			}
		},
		"query": {
			"bool": {
				"must": [
					{
						"term": {
							"tenant.keyword": userContext.tenantId
						}
					}
				]
			}
		},
		"size": 0
	}

	const loadTotal = async () => {
		const resultJSON = await client.graphql({
			query: esQuery,
			variables: {
				query: JSON.stringify(docCountQuery),
				action: '_search',
				model: 'contact'
			}
		});

		const queryResult = JSON.parse(resultJSON.data.esQuery);
		console.log('queryResult', queryResult);
		const docHits = queryResult?.hits?.total ? queryResult.hits.total : 0;
		setTotalDocs(docHits);
	}

	const searchContacts = async (query, deletedContact) => {
		// setLoading(true);
		if (!userContext.tenantId) {
			console.error('No Tenant ID');
			return {
				data: [],
				page: currentPage,
				totalCount: docCount,
			};
		}
		// try {
		let currentPageTokens = {
			...pageTokens,
		};
		console.log('query', query);
		const params = {
			tenant: userContext.tenantId,
		};

		if (query.search) {
			setSearchQuery(query.search);
			window.sessionStorage.setItem('ContactsSearch', query.search);
			params.searchQuery = query.search;
		}
		if (query.page > currentPage) {
			params.nextToken = nextToken;
			currentPageTokens[query.page] = nextToken;
		} else {
			if (query.search === searchQuery) {
				params.nextToken = currentPageTokens[query.page];
			} else {
				params.nextToken = null;
				currentPageTokens = {
					0: null,
				};
				setCurrentPage(0);
			}
		}
		if (query.pageSize) {
			params.limit = query.pageSize;
		}
		if (query.orderBy) {
			let sortField = `${query.orderBy.field}.keyword`;
			if (sortField.startsWith('custom:')) {
				const customName = sortField.replace('custom:', '');
				const customField = userContext.customFields.find(
					(customField) => customField.name === customName,
				);
				if (customField && customField.type === 'String') {
					sortField = `${sortField.replace('custom:', 'customFields.')}`;
				} else {
					sortField = `${sortField
						.replace('custom:', 'customFields.')
						.replace('.keyword', '')}`;
				}
			}
			params.sort = [{ field: sortField, direction: query.orderDirection }];
		}
		const simpleSearch = await client.graphql({
			query: simpleContactSearch,
			variables: params,
		});

		if (simpleSearch.data.simpleContactSearch !== null) {
			setDocCount(
				simpleSearch.data.simpleContactSearch.total === null
					? 0
					: simpleSearch.data.simpleContactSearch.total,
			);
			setNextToken(simpleSearch.data.simpleContactSearch.nextToken);
			setCurrentPage(query.page);
			setPageTokens(currentPageTokens);
			console.log(simpleSearch);
			setLoading(false);

			const data = {
				data: simpleSearch.data.simpleContactSearch.items,
				page: query.page,
				totalCount: simpleSearch.data.simpleContactSearch.total,
			}

			if (deletedContact) {
				data.data = data.data.filter(contact => contact.id !== deletedContact.id);
				data.totalCount = data.totalCount - 1;
			}

			return data;
		} else {
			// setLoading(false);
			return {
				data: [],
				page: query.page,
				totalCount: 0,
			};
		}
		// } catch (err) {
		// 	console.error(err);
		// 	setLoading(false);
		// }
	};

	function handleSearchChange(value) {
		window.sessionStorage.setItem('ProfilesSearch', value);
	}

	async function handleDelete() {
		try {
			setConfirmDeleteOpen(false);
			const deleteResult = await client.graphql({
				query: deleteContact,
				variables: { input: { id: selectedContact.id } },
			});

			// write the delete to the activity log

			searchContacts({ page: currentPage, pageSize: 10 }, deleteResult.data.deleteContact);

			enqueueSnackbar('Contact Deleted', {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} catch (err) {
			console.error(err);
			enqueueSnackbar('Error Deleting Contact', {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	}

	function handleCancelDelete() {
		setSelectedContact(null);
		setConfirmDeleteOpen(false);
	}

	return (
		<Box>
			<PageAppBar
				title="Contact Inventory"
				description="View and Manage Contacts"
				actionOneText="+ Contact"
				actionOneHandler={() => {
					setSelectedContact(null);
					setContactDialogOpen(true);
				}}
			/>
			{userContext.tenantId && <MaterialTable
				components={{
					// Toolbar: toolBar,
					Container: (props) => <Box {...props} elevation={0} />,
				}}
				title={`${docCount} of ${totalDocs} Contacts`}
				data={searchContacts}
				detailPanel={({ rowData }) => {
					const customFields = JSON.parse(rowData.customFields);
					console.log('customFields', customFields);
					return (
						<Card variant="outlined">
							<CardHeader title="Custom Fields" titleTypographyProps={{
								variant: "body2",
								color: "textSecondary",
							}} />
							<CardContent>
								<Table size="small">
									<TableHead>
										<TableRow>
											{userContext.customFields.map((field) => {
												if (!customFields[field.name]) {
													return null;
												} else {
													return (
														<TableCell variant='h6' key={field.name}>{field.name}</TableCell>
													);
												}
											})}
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											{userContext.customFields.map((field) => {
												if (!customFields[field.name]) {
													return null;
												} else if (field.type === 'Boolean') {
													return (
														<TableCell key={field.name}>
															{customFields[field.name] ? <CheckCircleOutline color="primary" /> : 'False'}
														</TableCell>
													);
												}
												return (
													<TableCell key={field.name}>
														{customFields[field.name]}
													</TableCell>
												);
											})}
										</TableRow>
									</TableBody>
								</Table>
							</CardContent>
						</Card>
					);
				}}
				columns={[
					{
						title: 'External ID', field: 'externalId', width: '20%', render: (rowData) => (
							<div
								style={{
									maxHeight: "26px",
									overflow: "hidden",
								}}
							>
								{rowData.externalId}
							</div>)
					},
					{ title: 'First Name', field: 'firstName' },
					{ title: 'Last Name', field: 'lastName' },
					{ title: 'Source', field: 'source' },
					{ title: 'Phone', field: 'phone' },
					{ title: 'Cell', field: 'cell' },
					{ title: 'State', field: 'state' },
					{ title: 'Zip Code', field: 'zip' },
				]}
				isLoading={loading}
				options={{
					maxBodyHeight: 'calc(100vh - 350px)',
					actionsColumnIndex: -1,
					pageSize: 10,
					searchText: window.sessionStorage.getItem('ProfilesSearch'),
					searchAutoFocus: true,
					searchFieldStyle: {
						marginBottom: '16px',
						marginleft: '-28px',
					},
					headerStyle: {
						borderTop: '1px solid #e0e0e0',
						padding: '16px',
					},
					searchFieldVariant: 'outlined',
					paginationType: 'stepped',
				}}
				onSearchChange={handleSearchChange}
				actions={[
					{
						icon: () => <VisibilityOutlined color="primary" />,
						tooltip: 'Quick View/Edit',
						onClick: (event, rowData) => {
							console.log('rowData', rowData);
							const contact = JSON.parse(JSON.stringify(rowData));
							delete contact.tableData;
							contact.customFields = JSON.parse(contact.customFields);
							setContactDialogOpen(true);
							setSelectedContact(contact);
						},
					},
					{
						icon: () => <EditOutlined color="primary" />,
						tooltip: 'Edit Contact',
						onClick: (event, rowData) => {
							navigate(`/contacts/${rowData.id}`);
						},
					},
					{
						icon: () => <DeleteOutlineOutlined color="primary" />,
						tooltip: 'Delete Contact',
						onClick: (event, rowData) => {
							setSelectedContact(rowData);
							setConfirmDeleteOpen(true);
						},
					},
				]}
				onRowClick={(event, rowData) => {
					navigate(`/contacts/${rowData.id}`);
				}}
			/>}
			<ConfirmDialog
				open={confirmDeleteOpen}
				title="Delete Contact"
				description={`Are you sure you want to delete ${selectedContact?.firstName} ${selectedContact?.lastName}?`}
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDelete();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					handleCancelDelete();
				}}
			/>
			<ContactDialog
				contact={selectedContact}
				contactDialogOpen={contactDialogOpen}
				setContactDialogOpen={setContactDialogOpen}
				onClose={() => {
					setContactDialogOpen(false);
				}}
				onSave={() => {
					setContactDialogOpen(false);
					searchContacts({ page: currentPage, pageSize: 10 });
				}}
			/>
		</Box>
	);
}
