import { useEffect, useState, useContext, createRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from 'src/contexts/UserContext';
import { Typography, TextField, Divider, Select, MenuItem, InputLabel, FormControl, Box, useMediaQuery, Drawer, Chip } from '@mui/material';
import { Email, House, Link, Person, Phone } from '@mui/icons-material';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { enqueueSnackbar } from 'notistack';
import { PageAppBar } from 'src/components/pageAppBar';
import { generateClient } from 'aws-amplify/api';
import { getPaymentTemplate } from 'src/graphql/queries';
import { createPaymentTemplate, updatePaymentTemplate } from 'src/graphql/mutations';
import { Editor } from 'src/components/editor/editor';

const newTemplate = {
	id: '',
	tenant: '',
	name: '',
	textBody: '',
	type: 'PlainText'
};

const DRAWER_WIDTH = 300;

export function MessageTemplate() {
	const { id } = useParams();
	const userContext = useContext(UserContext);
	const client = generateClient();
	const navigate = useNavigate();

	const useSingleColumn = useMediaQuery('(max-width:845px)');
	const usePersistentDrawer = useMediaQuery('(min-width:1200px)');

	const [placeholderToInsert, setPlaceholderToInsert] = useState('');

	const [template, setTemplate] = useState(newTemplate);
	const [loading, setLoading] = useState(false);

	const [showPlaceholders, setShowPlaceHolders] = useState(localStorage.getItem('showPlaceholders') === 'true' || false);

	const formikRef = createRef();

	useEffect(() => {
		async function getData() {
			setLoading(true);
			const savedTemplate = await client.graphql({
				query: getPaymentTemplate,
				variables: { id }
			});
			setLoading(false);
			if (savedTemplate.data) {
				document.title = `Message Template - ${savedTemplate.data.getPaymentTemplate.name}`;
				setTemplate(savedTemplate.data.getPaymentTemplate);
			}
		}

		if (id && id !== 'new') {
			getData();
		} else if (id === 'new') {
			setTemplate(newTemplate);
		}
	}, [id]);

	const addPlaceholder = (input) => () => {
		setPlaceholderToInsert(`~${input}~`);
		setTimeout(() => {
			setPlaceholderToInsert('');
		}, 100);

		// Close the drawer if we are not in persistent drawer mode.
		if (!usePersistentDrawer) {
			setShowPlaceHolders(false);
			localStorage.setItem('showPlaceholders', 'false');
		}
	};

	return (
		<div style={{ margin: 'auto', maxWidth: '1800px' }}>

			<Box sx={{ display: "flex" }}>

				{/* Content */}
				<Box sx={{
					flexGrow: 1,
				}}>
					<Formik
						initialValues={template}
						enableReinitialize={true}
						innerRef={formikRef}
						validationSchema={object(
							{
								name: string()
									.required('A template name is required')
									.trim().matches(/^[a-zA-Z0-9 -]+$/),
								textBody: string()
									.required('Message text is required'),
								type: string()
									.required('A message type is required')

							}
						)}
						onSubmit={async (values, formikBag) => {
							setLoading(true);
							const isNewMessageTemplate = id === 'new';

							// Modify the message template.
							const messageTemplate = {
								...values,
								tenant: userContext.tenantId
							};
							delete messageTemplate.createdAt;
							delete messageTemplate.updatedAt;
							if (isNewMessageTemplate) {
								delete messageTemplate.id;
							}

							// Create or update the message template.
							try {
								const mutation = isNewMessageTemplate ? createPaymentTemplate : updatePaymentTemplate;
								const graphqlResult = await client.graphql({
									query: mutation,
									variables: { input: messageTemplate }
								});
								enqueueSnackbar(
									`Template ${isNewMessageTemplate ? 'Created' : 'Updated'}`,
									{ variant: 'success' }
								);
								formikBag.setSubmitting(false);
								setLoading(false);

								const savedTemplate = graphqlResult.data[isNewMessageTemplate ? 'createPaymentTemplate' : 'updatePaymentTemplate'];
								if (savedTemplate.id === id) {
									setTemplate(savedTemplate);
									formikBag.resetForm(savedTemplate);
								} else {
									navigate(`/message-templates/${savedTemplate.id}`);
								}
							} catch (error) {
								console.error(error);
								enqueueSnackbar(
									`Failed to ${isNewMessageTemplate ? 'create' : 'update'} the template`,
									{
										variant: 'error',
										autoHideDuration: 5000
									}
								);
							}
						}}
					>
						{formikProps => (
							<>
								<PageAppBar
									title={template.name || 'New Template'}
									description="Update the content for your message template"
									actionOneText="Save"
									actionOneHandler={() => {
										formikProps.handleSubmit();
									}}
									// actionOneDisabled={!formikProps.dirty}
									actionTwoText="Cancel"
									actionTwoHandler={() => {
										navigate('/message-templates');

									}}
								/>

								<form onSubmit={formikProps.handleSubmit}>

									<Box
										display="grid"
										gridTemplateColumns={useSingleColumn ? "1fr" : "1fr 1fr"}
										gap="20px"
									>

										{/* Name */}
										<TextField
											color="primary"
											size="small"
											name="name"
											label="Template Name"
											type="text"
											autoComplete="off"
											onBlur={formikProps.handleBlur}
											onChange={formikProps.handleChange}
											value={formikProps.values.name}
											error={formikProps.errors.name && formikProps.touched.name}
											required
										/>

										{/* Message Type */}
										<FormControl fullWidth size="small" required>
											<InputLabel id="message-type-label" htmlFor="message-type" color="primary">Message Type</InputLabel>
											<Select
												inputProps={{ id: 'message-type' }}
												value={formikProps.values.type}
												label="Message Type"
												labelId="message-type-label"
												color="primary"
												onChange={(event) => {
													formikProps.setFieldValue('type', event.target.value);
												}}
												onBlur={formikProps.handleBlur}
											>
												<MenuItem value='PlainText'>Plain Text</MenuItem>
												<MenuItem value='HTML'>HTML</MenuItem>
												<MenuItem value='RawHTML'>Raw HTML</MenuItem>
											</Select>
										</FormControl>
									</Box>

									<br></br>

									{/* Template Content */}
									<Editor
										initialValue={template.textBody}
										disabled={loading}
										richText={formikProps.values.type === "HTML"}
										showToolbar={true}
										showTreeView={false}
										placeholderToInsert={placeholderToInsert}
										placeholder="Enter your message here..."
										onContentChange={(content) => {
											formikProps.setFieldValue('textBody', content);
										}}
										onPlaceholderButtonClick={() => {
											setShowPlaceHolders(!showPlaceholders);
											localStorage.setItem('showPlaceholders', !showPlaceholders);
										}}
									></Editor>

								</form>
							</>
						)}
					</Formik>
				</Box>


				{/* Drawer */}
				<Box
					sx={{
						width: { sm: DRAWER_WIDTH },
						flexShrink: { sm: 0 },
						display: usePersistentDrawer && showPlaceholders ? 'fixed' : 'none',
					}}
				>
					<Drawer
						variant={usePersistentDrawer ? "persistent" : "temporary"}
						open={showPlaceholders}
						onClose={() => {
							if (showPlaceholders) {
								setShowPlaceHolders(false);
								localStorage.setItem('showPlaceholders', 'false');
							}
						}}
						anchor="right"
						sx={{
							'& .MuiDrawer-paper': {
								boxSizing: 'border-box',
								width: DRAWER_WIDTH,
								padding: '15px',
							},
						}}
					>
						<Typography variant="h6" style={{ paddingBottom: '20px' }}>Placeholders</Typography>

						<Typography variant="subtitle" style={{ padding: '10px 0' }}>Contact</Typography>
						<Divider style={{ marginBottom: '10px' }} />
						<Box display="flex" flexWrap="wrap" justifyContent="center">
							<Chip label="First Name" icon={<Person size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('FirstName')}></Chip>
							<Chip label="Last Name" icon={<Person size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('LastName')}></Chip>
							<Chip label="Source" icon={<Person size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('Source')}></Chip>
							<Chip label="Phone" icon={<Phone size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('Phone')}></Chip>
							<Chip label="Cell" icon={<Phone size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('Cell')}></Chip>
							<Chip label="Email" icon={<Email size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('Email')}></Chip>
							<Chip label="Address 1" icon={<House size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('Address1')}></Chip>
							<Chip label="Address 2" icon={<House size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('Address2')}></Chip>
							<Chip label="City" icon={<House size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('City')}></Chip>
							<Chip label="State" icon={<House size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('State')}></Chip>
							<Chip label="Zip" icon={<House size="small" />} variant="outlined" color='primary' sx={{ margin: '5px' }} onClick={addPlaceholder('Zip')}></Chip>
							<Chip label="Action Link" icon={<Link size="small" />} variant="outlined" color="primary" sx={{ margin: '5px' }} onClick={addPlaceholder('PayLink')}></Chip>
						</Box>

						<br></br>

						<Typography variant="subtitle" style={{ padding: '10px 0' }}>Custom Fields</Typography>
						<Divider style={{ marginBottom: '10px' }} />
						<Box display="flex" flexWrap="wrap" justifyContent="center">
							{userContext?.customFields?.sort((fieldA, fieldB) => fieldA.displayName.localeCompare(fieldB.displayName)
							).map((customField) => (
								<Chip label={customField.displayName} variant="outlined" color='primary' sx={{ margin: '5px' }} key={customField.name} onClick={addPlaceholder(customField.name)}></Chip>
							))}
						</Box>
					</Drawer>
				</Box>

			</Box>
		</div >
	);
}
