import { useContext, useEffect, useState, useRef } from "react";
import {
	Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem,
	TextField, FormHelperText, Button, LinearProgress, Tabs, Tab, Divider,
	Box
} from "@mui/material";
import { Formik } from "formik";
import _ from 'lodash';
import { UserContext } from '../../contexts/UserContext';
import { object, string, mixed, array, boolean, bool, number } from 'yup';
import { getSuppression, getTenantSettings, listSuppressions } from '../../graphql/queries';
import { createSuppression, updateSuppression } from '../../graphql/mutations';
import { AssignmentOutlined, ContactlessOutlined, FilterListOutlined, PhoneDisabledOutlined, RssFeedOutlined, ClearAllOutlined } from "@mui/icons-material";
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from "react-router-dom";
import { SuppressionDefaults, SuppressionErrorDefaults } from "../../models/suppressionResults";
import RestService, { responseTypes, methods, stringTransformations } from '../../components/engagement/suppression/restService';
import DNCSuppression from "../../components/engagement/suppression/dncSuppression";
import ActivityLogSuppression from "../../components/engagement/suppression/activityLogSuppression";
import PocSuppression from "../../components/engagement/suppression/pocSuppression";
import DNCLists from "../../components/engagement/suppression/dncLists";
import ContactFieldSuppressionLists from "../../components/engagement/suppression/contactFieldSuppressionLists";
import Tester from "../../components/engagement/suppression/tester";
import { generateClient } from "aws-amplify/api";
import { PageAppBar } from "src/components/pageAppBar";
import { tabStyle } from "src/theme";

export default function Suppression() {
	const client = generateClient();
	const { enqueueSnackbar } = useSnackbar();
	const { suppressionId } = useParams();
	const navigate = useNavigate();
	const userContext = useContext(UserContext);
	const [suppressionObj, setSuppressionObj] = useState({
		name: '',
		result: '',
		activityLogQuerySuppressionEnabled: true,
		activityLogQuerySuppression: {
			query: {},
			rawQuery: { rules: [] },
			threshold: 0,
		},
		pocQuerySuppressionEnabled: true,
		pocQuerySuppression: {
			query: {},
			rawQuery: { rules: [] },
			suppressIfWeekendAndCantCallOnWeekends: false
		},
		restSuppressionEnabled: true,
		restSuppression: {
			endpoint: '',
			method: methods.GET,
			params: [],
			headers: [],
			auth: {
				type: 'None',
				info: {}
			},
			response: {
				path: '',
				type: responseTypes.Boolean,
				value: {
					value: true
				},
				options: {
					responseStringTransformation: stringTransformations.ToLower.name
				}
			},
			suppressOnError: true,
			dispositionOnError: SuppressionErrorDefaults[2].dynamo
		},
		dncDotComEnabled: false,
		dncDotCom: {
			scrub: true,
			state: true,
			numberReassignment: true,
			knownLitigator: true,
			reassignmentSince: ''
		},
		dncListSuppressionEnabled: true,
		dncListSuppressions: [],
		contactFieldSuppressionEnabled: false,
		contactFieldSuppressions: []
	});
	const [invalidId, setInvalidId] = useState(false);
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [logs, setLogs] = useState([]);
	const [dncDotComSettings, setDncDotComSettings] = useState({ orgId: '', projectId: '', loginId: '' });
	const isNew = useRef(false);

	useEffect(() => {
		async function getData() {
			setLoading(true);

			if (suppressionId === "new") {
				isNew.current = true;
				await getDncDotComSettings();
			} else if (!isNew.current) {
				const dncDotComSettings = await getDncDotComSettings();
				isNew.current = false;

				const result = await client.graphql({
					query: getSuppression,
					variables: { id: suppressionId },
				});

				console.log(result);

				if (result.data?.getSuppression) {
					result.data.getSuppression.activityLogQuerySuppression.rawQuery = JSON.parse(result.data?.getSuppression.activityLogQuerySuppression.rawQuery);
					result.data.getSuppression.activityLogQuerySuppression.query = JSON.parse(result.data?.getSuppression.activityLogQuerySuppression.query);
					result.data.getSuppression.pocQuerySuppression.rawQuery = JSON.parse(result.data?.getSuppression.pocQuerySuppression.rawQuery);
					result.data.getSuppression.pocQuerySuppression.query = JSON.parse(result.data?.getSuppression.pocQuerySuppression.query);
					result.data.getSuppression.restSuppression.auth.info = JSON.parse(result.data.getSuppression.restSuppression.auth.info);
					result.data.getSuppression.restSuppression.response.value = JSON.parse(result.data.getSuppression.restSuppression.response.value);
					result.data.getSuppression.restSuppression.response.options = JSON.parse(result.data.getSuppression.restSuppression.response.options);
					if (!(dncDotComSettings.orgId && dncDotComSettings.projectId && dncDotComSettings.loginId) && result.data.getSuppression.dncDotComEnabled) {
						result.data.getSuppression.dncDotComEnabled = false;
						const suppressionClone = _.cloneDeep(result.data.getSuppression);
						delete suppressionClone.createdAt;
						delete suppressionClone.updatedAt;
						suppressionClone.activityLogQuerySuppression.rawQuery = JSON.stringify(suppressionClone.activityLogQuerySuppression.rawQuery);
						suppressionClone.activityLogQuerySuppression.query = JSON.stringify(suppressionClone.activityLogQuerySuppression.query);
						suppressionClone.pocQuerySuppression.rawQuery = JSON.stringify(suppressionClone.pocQuerySuppression.rawQuery);
						suppressionClone.pocQuerySuppression.query = JSON.stringify(suppressionClone.pocQuerySuppression.query);
						suppressionClone.restSuppression.auth.info = JSON.stringify(suppressionClone.restSuppression.auth.info);
						suppressionClone.restSuppression.response.value = JSON.stringify(suppressionClone.restSuppression.response.value);
						suppressionClone.restSuppression.response.options = JSON.stringify(suppressionClone.restSuppression.response.options);

						await client.graphql({
							query: updateSuppression,
							variables: { input: suppressionClone },
						});

					}

					if (!result.data.getSuppression.contactFieldSuppressionEnabled || !result.data.getSuppression.contactFieldSuppressions) {
						result.data.getSuppression.contactFieldSuppressionEnabled = false;
						result.data.getSuppression.contactFieldSuppressions = [];
					}

					setSuppressionObj(result.data?.getSuppression);
				} else {
					setInvalidId(true);
				}
			}

			setLoading(false);
		}

		if (userContext.tenantId && suppressionId) {
			getData();
		}
	}, [userContext, suppressionId]);

	async function getDncDotComSettings() {
		const tenantSettings = await client.graphql({
			query: getTenantSettings,
			variables: { id: userContext.tenantId },
		});

		dncDotComSettings.orgId = tenantSettings?.data?.getTenantSettings?.dncDotComSettings?.orgId;
		dncDotComSettings.projectId = tenantSettings?.data?.getTenantSettings?.dncDotComSettings?.projectId;
		dncDotComSettings.loginId = tenantSettings?.data?.getTenantSettings?.dncDotComSettings?.loginId;
		setDncDotComSettings({ ...dncDotComSettings });
		return dncDotComSettings;
	}

	function containsValidError(errors, touched) {
		if (!errors || !touched) return false;

		return Object.keys(errors ?? {}).some(x => x in touched);
	}

	async function handleClose(dirty) {
		// if (!dirty || await showDialog({ children: 'You have unsaved changes. Are you sure you want to leave this page?', confirmTxt: 'Yes', cancelTxt: 'Cancel' }) === DialogResult.Confirm) {
		navigate('/suppression');
		// }
	}

	return (
		<Formik
			initialValues={suppressionObj}
			enableReinitialize
			validationSchema={object().shape({
				name: string().required("A name is required"),
				result: string().required("A suppression result is required."),
				activityLogQuerySuppression: mixed().when('activityLogQuerySuppressionEnabled', {
					is: true,
					then: () => object({
						threshold: number().min(0, "The threshold must be positive.").required("A threshold is required."),
						rawQuery: mixed().test({
							name: "Empty query test",
							message: "A query is required",
							test: value => {
								return value?.rules?.length > 0;
							}
						})
					})
				}),
				pocQuerySuppression: mixed().when('pocQuerySuppressionEnabled', {
					is: true,
					then: () => object({
						rawQuery: mixed().when('suppressIfWeekendAndCantCallOnWeekends', {
							is: false,
							then: () => mixed().test({
								name: "Empty query test",
								message: "Please specify a suppression criteria.",
								test: value => {
									return value?.rules?.length > 0;
								}
							})
						})
					})
				}),
				restSuppression: mixed().when('restSuppressionEnabled', {
					is: true,
					then: () => object({
						endpoint: string().required("An endpoint is required").url('The endpoint must be a valid URL'),
						method: string().required(),
						response: object({
							path: string().trim().required('A response key is required'),
							type: string().required('A response type is required'),
							value: object({
								value: mixed().test({
									name: 'Valid number',
									message: 'Please specify a valid number',
									test: function (value) {
										return !(this.from[1].value.type === responseTypes.Number && value == null);
									}
								})
							})
						}),
						headers: array().of(object({
							key: string().required("This field is required")
						}))
					})
				}),
				dncDotCom: mixed().when('dncDotComEnabled', {
					is: true,
					then: () => object({
						scrub: boolean().test(function (value) {
							return value || this.parent.numberReassignment || this.parent.knownLitigator || this.parent.state
						}),
						state: boolean().test(function (value) {
							return value || this.parent.numberReassignment || this.parent.knownLitigator || this.parent.scrub
						}),
						reassignmentSince: mixed().when('numberReassignment', {
							is: true,
							then: () => string().required("This field is required").matches(/^((\d{4}-\d{2}-\d{2})|(now-\d+))$/)
						})
					})
				}),
				dncListSuppressions: mixed().when('dncListSuppressionEnabled', {
					is: true,
					then: () => array().min(1, "At least one list must be selected")
				}),
				contactFieldSuppressions: mixed().when('contactFieldSuppressionEnabled', {
					is: true,
					then: () => array().min(1, "At least one contact field must be selected")
				}),
				activityLogQuerySuppressionEnabled: bool().test(function (value) { // Make sure that at least one type is enabled.
					return value || this.parent.pocQuerySuppressionEnabled || this.parent.restSuppressionEnabled || this.parent.dncDotComEnabled || this.parent.dncListSuppressionEnabled || this.parent.contactFieldSuppressionEnabled;
				}),
			})}
			onSubmit={async values => {
				setSaving(true);

				const duplicateName = await client.graphql({
					query: listSuppressions,
					variables: {
						filter: {
							name: {
								eq: values.name
							}
						}
					}
				});


				if (duplicateName?.data?.listSuppressions?.items?.some(x => x.id !== suppressionId)) {
					enqueueSnackbar(`A suppression profile with the name '${values.name}' already exists.`, { variant: 'error' });
				} else {
					let mutation = updateSuppression;

					if (suppressionId === "new") {
						mutation = createSuppression;
					}

					const clone = _.cloneDeep(values);

					delete clone.createdAt;
					delete clone.updatedAt;
					clone.activityLogQuerySuppression.rawQuery = JSON.stringify(clone.activityLogQuerySuppression.rawQuery);
					clone.activityLogQuerySuppression.query = JSON.stringify(clone.activityLogQuerySuppression.query);
					clone.pocQuerySuppression.rawQuery = JSON.stringify(clone.pocQuerySuppression.rawQuery);
					clone.pocQuerySuppression.query = JSON.stringify(clone.pocQuerySuppression.query);

					clone.restSuppression.auth.info = JSON.stringify(clone.restSuppression.auth.info ?? {});
					clone.restSuppression.response.value = JSON.stringify(clone.restSuppression.response.value);
					clone.restSuppression.response.options = JSON.stringify(clone.restSuppression.response.options);
					clone.tenant = userContext.tenantId;
					try {
						const result = await client.graphql({
							query: mutation,
							variables: { input: clone },
						});
						if (suppressionId === "new") {
							values.id = result.data.createSuppression.id;
							navigate(`/suppression/${result.data.createSuppression.id}`);
						}
						setSuppressionObj({ ...values });
						enqueueSnackbar("Suppression saved successfully", { variant: 'success' });
					} catch (err) {
						console.log(err);
						enqueueSnackbar("Suppression could not be saved", { variant: 'error' });
					}
				}

				setSaving(false);
			}}
		>
			{formikProps => (
				<div>
					<form onSubmit={formikProps.handleSubmit}>
						<Box display='flex' flexDirection='column' gap={2}>
							<PageAppBar
								title="Suppression Profile"
								description="Create or edit a suppression profile."
								actionOneText="Save"
								actionOneHandler={formikProps.handleSubmit}
								actionTwoText="Close"
								actionTwoHandler={() => handleClose(formikProps.dirty)}
								actionTwoDisabled={saving || loading}
							/>
							<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
								<TextField
									required
									label='Name'
									fullWidth
									value={formikProps.values.name}
									name='name'
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
									error={formikProps.errors.name && formikProps.touched.name}
									helperText={formikProps.touched.name && formikProps.errors.name} />
								<FormControl required error={formikProps.errors?.result && formikProps.touched?.result}>
									<InputLabel>Result</InputLabel>
									<Select
										label='Result'
										value={formikProps.values.result}
										onChange={formikProps.handleChange}
										onBlur={formikProps.handleBlur} name='result'
									>
										{SuppressionDefaults.map((result, index) =>
											!result.dynamo.includes('Rest') && <MenuItem key={index} value={result.dynamo}>{result.display}</MenuItem>)}
									</Select>
								</FormControl>
							</Box>
							<Box>
								<Tabs value={currentTab} onChange={(_, newValue) => setCurrentTab(newValue)}>
									<Tab
										sx={tabStyle}
										value={0}
										label={
											<Grid container spacing={1} direction="column">
												<Grid item>
													Activity
												</Grid>
												<Grid item>
													<Typography
														color={formikProps.values.activityLogQuerySuppressionEnabled ? 'primary' : 'error'}
														style={{ fontSize: 'x-small' }}
													>
														{formikProps.values.activityLogQuerySuppressionEnabled ? 'Enabled' : 'Disabled'}
													</Typography>
												</Grid>
											</Grid>}
									/>
									<Tab
										sx={tabStyle}
										value={1}
										label={
											<Grid container spacing={1} direction="column">
												<Grid item>
													Point of Contact
												</Grid>
												<Grid item>
													<Typography
														color={formikProps.values.pocQuerySuppressionEnabled ? 'primary' : 'error'}
														style={{ fontSize: 'x-small' }}
													>
														{formikProps.values.pocQuerySuppressionEnabled ? 'Enabled' : 'Disabled'}
													</Typography>
												</Grid>
											</Grid>}
									/>
									<Tab
										sx={tabStyle}
										value={2}
										label={
											<Grid container spacing={1} direction="column">
												<Grid item >
													REST Service
												</Grid>
												<Grid item>
													<Typography
														color={formikProps.values.restSuppressionEnabled ? 'primary' : 'error'}
														style={{ fontSize: 'x-small' }}
													>
														{formikProps.values.restSuppressionEnabled ? 'Enabled' : 'Disabled'}
													</Typography>
												</Grid>
											</Grid>}
									/>
									<Tab
										sx={tabStyle}
										value={4}
										label={
											<Grid container spacing={1} direction="column">
												<Grid item>
													DNC Lists
												</Grid>
												<Grid item>
													<Typography
														color={formikProps.values.dncListSuppressionEnabled ? 'primary' : 'error'}
														style={{ fontSize: 'x-small' }}
													>
														{formikProps.values.dncListSuppressionEnabled ? 'Enabled' : 'Disabled'}
													</Typography>
												</Grid>
											</Grid>}
									/>
									<Tab
										sx={tabStyle}
										value={5}
										label={
											<Grid container spacing={1} direction="column">
												<Grid item>
													Contact Field Suppression
												</Grid>
												<Grid item>
													<Typography
														color={formikProps.values.contactFieldSuppressionEnabled ? 'primary' : 'error'}
														style={{ fontSize: 'x-small' }}
													>
														{formikProps.values.contactFieldSuppressionEnabled ? 'Enabled' : 'Disabled'}
													</Typography>
												</Grid>
											</Grid>}
									/>
									{dncDotComSettings.orgId && dncDotComSettings.projectId &&
										<Tab
											sx={tabStyle}
											value={3}
											label={
												<Grid container spacing={1} direction="column">
													<Grid item>
														DNC.com
													</Grid>
													<Grid item>
														<Typography
															color={formikProps.values.dncDotComEnabled ? 'primary' : 'error'}
															style={{ fontSize: 'x-small' }}
														>
															{formikProps.values.dncDotComEnabled ? 'Enabled' : 'Disabled'}
														</Typography>
													</Grid>
												</Grid>}
										/>}
								</Tabs>
							</Box>
							<Box>
								<Grid item>

									{formikProps.touched?.result && formikProps.errors?.result &&
										<FormHelperText>{formikProps.errors.result}</FormHelperText>}
								</Grid>
								<Grid item>

									<Divider />
								</Grid>
								{formikProps.errors.activityLogQuerySuppressionEnabled &&
									<Grid item>
										<Typography variant='body1' color='error'>You must have at least one suppression type enabled.</Typography>
									</Grid>}
								{currentTab === 0 &&
									<Grid item>
										<ActivityLogSuppression formikProps={formikProps} tenant={userContext.tenantId} />
									</Grid>}
								{currentTab === 1 &&
									<Grid item>
										<PocSuppression formikProps={formikProps} tenant={userContext.tenantId} />
									</Grid>}
								{currentTab === 2 &&
									<Grid item>
										<RestService formikProps={formikProps} containsValidError={containsValidError} logs={logs} setLogs={setLogs} />
									</Grid>}
								{currentTab === 3 &&
									<Grid item>
										<DNCSuppression formikProps={formikProps} config={dncDotComSettings} />
									</Grid>}
								{currentTab === 4 &&
									<Grid item>
										<DNCLists
											errors={formikProps.errors.dncListSuppressions}
											lists={formikProps.values.dncListSuppressions}
											onChange={formikProps.handleChange}
											enabled={formikProps.values.dncListSuppressionEnabled}
											containsValidError={containsValidError}
											touched={formikProps.touched.dncListSuppressions} />
									</Grid>}
								{currentTab === 5 &&
									<Grid item>
										<ContactFieldSuppressionLists
											errors={formikProps.errors.contactFieldSuppressions}
											lists={formikProps.values.contactFieldSuppressions}
											onChange={formikProps.handleChange}
											enabled={formikProps.values.contactFieldSuppressionEnabled}
											containsValidError={containsValidError}
											touched={formikProps.touched.contactFieldSuppressions} />
									</Grid>}
							</Box>
							<Box>
								<Tester formikProps={formikProps} logs={logs} setLogs={setLogs} dncDotComSettings={dncDotComSettings} />
							</Box>
						</Box>
					</form>
				</div>
			)}
		</Formik>
	);
}
